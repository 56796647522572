import React from 'react';
import storybyteIcon from '../assets/Asset 4.png';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Add as AddIcon,
  Menu as MenuIcon,
  ChevronRight as ChevronRightIcon,
  LibraryBooks as LibraryIcon,
  Class as ClassIcon,
  Person as PersonIcon,
  Public as PublicIcon,
  Info as InfoIcon,
  Login as LoginIcon,
  PersonAdd as SignUpIcon,
} from '@mui/icons-material';

interface NavbarProps {
  isAuthenticated: boolean;
  onLogout: () => void;
  isTransparent?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ isAuthenticated, onLogout, isTransparent = false }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [openSubMenu, setOpenSubMenu] = React.useState<string | null>(null);

  const [libraryAnchorEl, setLibraryAnchorEl] = React.useState<null | HTMLElement>(null);
  const [classesAnchorEl, setClassesAnchorEl] = React.useState<null | HTMLElement>(null);
  const [profileAnchorEl, setProfileAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleSubMenuToggle = (menu: string) => {
    setOpenSubMenu(openSubMenu === menu ? null : menu);
  };

  const handleNavigation = (path: string) => () => {
    navigate(path);
    setDrawerOpen(false);
    setOpenSubMenu(null);
  };

  const handleMenuOpen = (setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

  const handleMenuClose = (setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => () => {
    setAnchorEl(null);
  };

  const navButtonStyle = {
    color: isTransparent ? 'white' : 'black',
    '&:hover': {
      backgroundColor: isTransparent ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
    },
    textTransform: 'none',
    fontWeight: 500,
    ...(isTransparent && {
      textShadow: '0 2px 8px rgba(0,0,0,0.5)',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'radial-gradient(circle at center, rgba(255,255,255,0.1) 0%, transparent 70%)',
        filter: 'blur(4px)',
        zIndex: -1
      }
    })
  };

  const MobileDrawer = () => (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={handleDrawerToggle}
      PaperProps={{
        sx: {
          width: 280,
          bgcolor: isTransparent ? 'rgba(0, 0, 0, 0.9)' : 'white',
        },
      }}
    >
      <List sx={{ color: isTransparent ? 'white' : 'inherit' }}>
        <ListItem button onClick={handleNavigation('/about')}>
          <ListItemIcon>
            <InfoIcon sx={{ color: isTransparent ? 'white' : 'inherit' }} />
          </ListItemIcon>
          <ListItemText primary="About" />
        </ListItem>

        {isAuthenticated ? (
          <>
            <ListItem button onClick={handleNavigation('/public')}>
              <ListItemIcon>
                <PublicIcon sx={{ color: isTransparent ? 'white' : 'inherit' }} />
              </ListItemIcon>
              <ListItemText primary="Public Feed" />
            </ListItem>

            <ListItem button onClick={() => handleSubMenuToggle('library')}>
              <ListItemIcon>
                <LibraryIcon sx={{ color: isTransparent ? 'white' : 'inherit' }} />
              </ListItemIcon>
              <ListItemText primary="Library" />
              <ChevronRightIcon
                sx={{
                  transform: openSubMenu === 'library' ? 'rotate(90deg)' : 'none',
                  transition: 'transform 0.3s',
                }}
              />
            </ListItem>
            <Collapse in={openSubMenu === 'library'} timeout="auto">
              <List component="div" disablePadding>
                <ListItem button sx={{ pl: 4 }} onClick={handleNavigation('/library')}>
                  <ListItemText primary="Public Library" />
                </ListItem>
                <ListItem button sx={{ pl: 4 }} onClick={handleNavigation('/my-library')}>
                  <ListItemText primary="My Library" />
                </ListItem>
              </List>
            </Collapse>

            <ListItem button onClick={() => handleSubMenuToggle('classes')}>
              <ListItemIcon>
                <ClassIcon sx={{ color: isTransparent ? 'white' : 'inherit' }} />
              </ListItemIcon>
              <ListItemText primary="Classes" />
              <ChevronRightIcon
                sx={{
                  transform: openSubMenu === 'classes' ? 'rotate(90deg)' : 'none',
                  transition: 'transform 0.3s',
                }}
              />
            </ListItem>
            <Collapse in={openSubMenu === 'classes'} timeout="auto">
              <List component="div" disablePadding>
                <ListItem button sx={{ pl: 4 }} onClick={handleNavigation('/classes')}>
                  <ListItemText primary="My Classes" />
                </ListItem>
                <ListItem button sx={{ pl: 4 }} onClick={handleNavigation('/classes/create')}>
                  <ListItemText primary="Create Class" />
                </ListItem>
                <ListItem button sx={{ pl: 4 }} onClick={handleNavigation('/classes/enroll')}>
                  <ListItemText primary="Join Class" />
                </ListItem>
              </List>
            </Collapse>

            <ListItem button onClick={() => handleSubMenuToggle('profile')}>
              <ListItemIcon>
                <PersonIcon sx={{ color: isTransparent ? 'white' : 'inherit' }} />
              </ListItemIcon>
              <ListItemText primary="Profile" />
              <ChevronRightIcon
                sx={{
                  transform: openSubMenu === 'profile' ? 'rotate(90deg)' : 'none',
                  transition: 'transform 0.3s',
                }}
              />
            </ListItem>
            <Collapse in={openSubMenu === 'profile'} timeout="auto">
              <List component="div" disablePadding>
                <ListItem button sx={{ pl: 4 }} onClick={handleNavigation('/profile')}>
                  <ListItemText primary="View Profile" />
                </ListItem>
                <ListItem button sx={{ pl: 4 }} onClick={handleNavigation('/profile/edit')}>
                  <ListItemText primary="Edit Profile" />
                </ListItem>
                <ListItem
                  button
                  sx={{ pl: 4 }}
                  onClick={() => {
                    onLogout();
                    handleDrawerToggle();
                  }}
                >
                  <ListItemText primary="Logout" />
                </ListItem>
              </List>
            </Collapse>

            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleNavigation('/classes/enroll')}
                sx={{
                  bgcolor: '#1a1a1a',
                  color: 'white',
                  py: 1,
                  borderRadius: 2,
                  textTransform: 'none',
                  fontWeight: 600,
                }}
              >
                Join Class
              </Button>

              <Button
                variant="contained"
                fullWidth
                startIcon={<AddIcon />}
                onClick={handleNavigation('/new')}
                sx={{
                  background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
                  color: 'white',
                  py: 1,
                  borderRadius: 2,
                  textTransform: 'none',
                  fontWeight: 600,
                }}
              >
                New Story
              </Button>
            </Box>
          </>
        ) : (
          <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Button
              fullWidth
              startIcon={<LoginIcon />}
              onClick={handleNavigation('/login')}
              sx={{
                bgcolor: isTransparent ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
                color: isTransparent ? 'white' : 'black',
                py: 1,
                borderRadius: 2,
                textTransform: 'none',
                fontWeight: 600,
              }}
            >
              Login
            </Button>
            <Button
              fullWidth
              variant="contained"
              startIcon={<SignUpIcon />}
              onClick={handleNavigation('/signup')}
              sx={{
                background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
                color: 'white',
                py: 1,
                borderRadius: 2,
                textTransform: 'none',
                fontWeight: 600,
              }}
            >
              Sign Up
            </Button>
          </Box>
        )}
      </List>
    </Drawer>
  );

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        backgroundColor: isTransparent ? 'transparent' : 'white',
        boxShadow: isTransparent ? 'none' : '0 2px 4px rgba(0,0,0,0.1)',
        zIndex: 1100,
      }}
    >
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <IconButton
            component={RouterLink}
            to="/"
            sx={{
              color: isTransparent ? 'white' : 'black',
              display: 'flex',
              alignItems: 'center',
              '&:hover': {
                backgroundColor: isTransparent ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
              },
              ...(isTransparent && {
                filter: 'drop-shadow(0 4px 12px rgba(0,0,0,0.5))',
                '&:hover': {
                  filter: 'drop-shadow(0 6px 16px rgba(0,0,0,0.6))',
                }
              })
            }}
          >
            <img
              src={storybyteIcon}
              alt="Storybyte Icon"
              style={{
                height: '35px',
                marginRight: '15px',
                display: 'block'
              }}
            />
            <Typography
              variant="h4"
              component="div"
              sx={{
                color: isTransparent ? 'white' : 'black',
                fontWeight: 'bold',
                display: { xs: 'none', sm: 'block' },
                ...(isTransparent && {
                  textShadow: `
                    0 4px 12px rgba(0,0,0,0.5),
                    0 8px 24px rgba(0,0,0,0.3)
                  `,
                  position: 'relative',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'radial-gradient(circle at center, rgba(255,255,255,0.1) 0%, transparent 70%)',
                    filter: 'blur(8px)',
                    zIndex: -1
                  }
                })
              }}
            >
              Storybyte
            </Typography>
          </IconButton>
        </Box>

        {!isMobile ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Button
              component={RouterLink}
              to="/about"
              sx={navButtonStyle}
            >
              About
            </Button>

            {isAuthenticated && (
              <Button
                component={RouterLink}
                to="/public"
                sx={navButtonStyle}
              >
                Public Feed
              </Button>
            )}

            {isAuthenticated ? (
              <>
                <Button
                  onClick={handleMenuOpen(setLibraryAnchorEl)}
                  endIcon={<ExpandMoreIcon sx={{ color: isTransparent ? 'white' : 'black' }} />}
                  sx={navButtonStyle}
                >
                  Library
                </Button>
                <Menu
                  anchorEl={libraryAnchorEl}
                  open={Boolean(libraryAnchorEl)}
                  onClose={handleMenuClose(setLibraryAnchorEl)}
                  PaperProps={{
                    elevation: 3,
                    sx: { mt: 1.5 }
                  }}
                >
                  <MenuItem onClick={handleNavigation('/library')} sx={{ minWidth: '180px', padding: '10px 20px' }}>
                    Public Library
                  </MenuItem>
                  <MenuItem onClick={handleNavigation('/my-library')} sx={{ minWidth: '180px', padding: '10px 20px' }}>
                    My Library
                  </MenuItem>
                </Menu>

                <Button
                  onClick={handleMenuOpen(setClassesAnchorEl)}
                  endIcon={<ExpandMoreIcon sx={{ color: isTransparent ? 'white' : 'black' }} />}
                  sx={navButtonStyle}
                >
                  Classes
                </Button>
                <Menu
                  anchorEl={classesAnchorEl}
                  open={Boolean(classesAnchorEl)}
                  onClose={handleMenuClose(setClassesAnchorEl)}
                  PaperProps={{
                    elevation: 3,
                    sx: { mt: 1.5 }
                  }}
                >
                  <MenuItem onClick={handleNavigation('/classes')} sx={{ minWidth: '180px', padding: '10px 20px' }}>
                    My Classes
                  </MenuItem>
                  <MenuItem onClick={handleNavigation('/classes/create')} sx={{ minWidth: '180px', padding: '10px 20px' }}>
                    Create Class
                  </MenuItem>
                  <MenuItem onClick={handleNavigation('/classes/enroll')} sx={{ minWidth: '180px', padding: '10px 20px' }}>
                    Join Class
                  </MenuItem>
                </Menu>

                <Button
                  onClick={handleMenuOpen(setProfileAnchorEl)}
                  endIcon={<ExpandMoreIcon sx={{ color: isTransparent ? 'white' : 'black' }} />}
                  sx={navButtonStyle}
                >
                  Profile
                </Button>
                <Menu
                  anchorEl={profileAnchorEl}
                  open={Boolean(profileAnchorEl)}
                  onClose={handleMenuClose(setProfileAnchorEl)}
                  PaperProps={{
                    elevation: 3,
                    sx: { mt: 1.5 }
                  }}
                >
                  <MenuItem onClick={handleNavigation('/profile')} sx={{ minWidth: '180px', padding: '10px 20px' }}>
                    View Profile
                  </MenuItem>
                  <MenuItem onClick={handleNavigation('/profile/edit')} sx={{ minWidth: '180px', padding: '10px 20px' }}>
                    Edit Profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      onLogout();
                      handleMenuClose(setProfileAnchorEl)();
                    }}
                    sx={{ minWidth: '180px', padding: '10px 20px' }}
                  >
                    Logout
                  </MenuItem>
                </Menu>

                <Button
                  onClick={() => navigate('/classes/enroll')}
                  sx={{
                    bgcolor: '#1a1a1a',
                    color: 'white',
                    px: 3,
                    py: 1,
                    borderRadius: 2,
                    textTransform: 'none',
                    fontWeight: 600,
                    '&:hover': {
                      bgcolor: '#333',
                    },
                    ...(isTransparent && {
                      textShadow: '0 2px 8px rgba(0,0,0,0.5)',
                      boxShadow: '0 4px 15px rgba(0,0,0,0.3)',
                      '&:hover': {
                        boxShadow: '0 6px 20px rgba(0,0,0,0.4)',
                        transform: 'translateY(-2px)',
                      }
                    })
                  }}
                >
                  Join Class
                </Button>

                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => navigate('/new')}
                  sx={{
                    background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
                    color: 'white',
                    px: 3,
                    py: 1,
                    borderRadius: 2,
                    textTransform: 'none',
                    fontWeight: 600,
                    boxShadow: '0 4px 15px rgba(255, 138, 0, 0.3)',
                    border: 'none',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #FF9500, #FF5F00)',
                      boxShadow: '0 6px 20px rgba(255, 138, 0, 0.4)',
                      transform: 'translateY(-2px)',
                    },
                    '&:active': {
                      transform: 'translateY(1px)',
                      boxShadow: '0 2px 10px rgba(255, 138, 0, 0.2)',
                    },
                    ...(isTransparent && {
                      textShadow: '0 2px 8px rgba(0,0,0,0.5)',
                      boxShadow: '0 4px 15px rgba(0,0,0,0.3), 0 4px 15px rgba(255, 138, 0, 0.3)',
                      '&:hover': {
                        boxShadow: '0 6px 20px rgba(0,0,0,0.4), 0 6px 20px rgba(255, 138, 0, 0.4)',
                        transform: 'translateY(-2px)',
                      }
                    })
                  }}
                >
                  New Story
                </Button>
              </>
            ) : (
              <>
                <Button
                  component={RouterLink}
                  to="/login"
                  sx={{
                    background: isTransparent ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.05)',
                    color: isTransparent ? 'white' : 'black',
                    px: 3,
                    py: 1,
                    borderRadius: 2,
                    textTransform: 'none',
                    fontWeight: 600,
                    fontSize: '1rem',
                    boxShadow: isTransparent ? '0 4px 15px rgba(255, 255, 255, 0.1)' : 'none',
                    backdropFilter: 'blur(10px)',
                    border: isTransparent ? '1px solid rgba(255,255,255,0.2)' : '1px solid rgba(0,0,0,0.1)',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      background: isTransparent ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.08)',
                      boxShadow: isTransparent ? '0 6px 20px rgba(255, 255, 255, 0.2)' : 'none',
                      transform: 'translateY(-2px)',
                    },
                    ...(isTransparent && {
                      textShadow: '0 2px 8px rgba(0,0,0,0.5)',
                    })
                  }}
                >
                  Login
                </Button>
                <Button
                  variant="contained"
                  onClick={() => navigate('/signup')}
                  sx={{
                    background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
                    color: 'white',
                    px: 3,
                    py: 1,
                    borderRadius: 2,
                    textTransform: 'none',
                    fontWeight: 600,
                    fontSize: '1rem',
                    boxShadow: '0 4px 15px rgba(255, 138, 0, 0.3)',
                    border: 'none',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #FF9500, #FF5F00)',
                      boxShadow: '0 6px 20px rgba(255, 138, 0, 0.4)',
                      transform: 'translateY(-2px)',
                    },
                    '&:active': {
                      transform: 'translateY(1px)',
                      boxShadow: '0 2px 10px rgba(255, 138, 0, 0.2)',
                    },
                    ...(isTransparent && {
                      textShadow: '0 2px 8px rgba(0,0,0,0.5)',
                      boxShadow: '0 4px 15px rgba(0,0,0,0.3), 0 4px 15px rgba(255, 138, 0, 0.3)',
                      '&:hover': {
                        boxShadow: '0 6px 20px rgba(0,0,0,0.4), 0 6px 20px rgba(255, 138, 0, 0.4)',
                        transform: 'translateY(-2px)',
                      }
                    })
                  }}
                >
                  Sign Up
                </Button>
              </>
            )}
          </Box>
        ) : (
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{
              color: isTransparent ? 'white' : 'black',
              ...(isTransparent && {
                textShadow: '0 2px 8px rgba(0,0,0,0.5)',
                filter: 'drop-shadow(0 4px 12px rgba(0,0,0,0.5))',
              })
            }}
          >
            <MenuIcon />
          </IconButton>
        )}

        <MobileDrawer />
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;