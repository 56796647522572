import React, { useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  TextField, 
  Button, 
  Typography, 
  Box, 
  CircularProgress, 
  Alert,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper
} from '@mui/material';
import {
  Check as CheckIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { API_BASE_URL } from '../config';

interface PasswordRequirement {
  regex: RegExp;
  message: string;
}

const passwordRequirements: PasswordRequirement[] = [
  { regex: /.{8,}/, message: 'At least 8 characters long' },
  { regex: /[A-Z]/, message: 'Contains uppercase letter' },
  { regex: /[a-z]/, message: 'Contains lowercase letter' },
  { regex: /[0-9]/, message: 'Contains number' },
  { regex: /[^A-Za-z0-9]/, message: 'Contains special character' },
];

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<{text: string; type: 'success' | 'error'} | null>(null);
  const { uid, token } = useParams<{uid: string; token: string}>();
  const navigate = useNavigate();

  const passwordStrength = useMemo(() => {
    return passwordRequirements.filter(req => req.regex.test(password)).length;
  }, [password]);

  const passwordStrengthPercent = (passwordStrength / passwordRequirements.length) * 100;

  const getStrengthColor = () => {
    if (passwordStrengthPercent <= 20) return 'error';
    if (passwordStrengthPercent <= 60) return 'warning';
    return 'success';
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (password !== confirmPassword) {
      setMessage({ text: 'Passwords do not match', type: 'error' });
      return;
    }

    if (passwordStrength < passwordRequirements.length) {
      setMessage({ text: 'Please meet all password requirements', type: 'error' });
      return;
    }

    setLoading(true);
    setMessage(null);

    try {
      const response = await axios.post(`${API_BASE_URL}/accounts/reset-password/`, {
        uid,
        token,
        new_password: password
      });
      
      setMessage({
        text: response.data.message || 'Password reset successful',
        type: 'success'
      });
      
      setTimeout(() => navigate('/login'), 3000);
    } catch (error) {
      setMessage({
        text: 'Failed to reset password. The link may be invalid or expired.',
        type: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ 
      mt: { xs: 12, sm: 14 },
      mx: 'auto',
      px: 2,
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }}>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          maxWidth: 400,
          width: '100%',
          borderRadius: 2,
          background: 'rgba(255, 255, 255, 0.98)',
          backdropFilter: 'blur(10px)'
        }}
      >
        <Typography 
          variant="h4" 
          component="h1" 
          gutterBottom
          sx={{
            fontWeight: 600,
            textAlign: 'center',
            mb: 3,
            color: '#1a1a1a'
          }}
        >
          Set New Password
        </Typography>

        {message && (
          <Alert 
            severity={message.type} 
            sx={{ 
              mb: 2,
              borderRadius: 1
            }}
          >
            {message.text}
          </Alert>
        )}

        <Box 
          component="form" 
          onSubmit={handleSubmit}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <Box>
            <TextField
              fullWidth
              label="New Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              disabled={loading}
              sx={{
                '& .MuiOutlinedInput-root': {
                  bgcolor: 'white'
                }
              }}
            />
            <LinearProgress 
              variant="determinate" 
              value={passwordStrengthPercent}
              color={getStrengthColor()}
              sx={{ mt: 1, borderRadius: 1 }}
            />
            <List dense>
              {passwordRequirements.map((req, index) => (
                <ListItem key={index} dense>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    {req.regex.test(password) ? (
                      <CheckIcon color="success" fontSize="small" />
                    ) : (
                      <CloseIcon color="error" fontSize="small" />
                    )}
                  </ListItemIcon>
                  <ListItemText 
                    primary={req.message} 
                    primaryTypographyProps={{ 
                      variant: 'caption',
                      color: req.regex.test(password) ? 'success.main' : 'error' 
                    }} 
                  />
                </ListItem>
              ))}
            </List>
          </Box>

          <TextField
            fullWidth
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            disabled={loading}
            error={confirmPassword !== '' && password !== confirmPassword}
            helperText={confirmPassword !== '' && password !== confirmPassword ? "Passwords don't match" : ''}
            sx={{
              '& .MuiOutlinedInput-root': {
                bgcolor: 'white'
              }
            }}
          />

          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={loading}
            sx={{ 
              py: 1.5,
              fontSize: '1rem',
              fontWeight: 500,
              textTransform: 'none',
              borderRadius: 1.5,
              bgcolor: '#1a1a1a',
              '&:hover': {
                bgcolor: '#333'
              }
            }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Reset Password'}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default ResetPassword;