import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { Container, CssBaseline, ThemeProvider, createTheme, Box } from '@mui/material';

// Component imports
import Dashboard from './components/Dashboard';
import LandingPage from './components/LandingPage';
import DiscordBanner from './components/DiscordBanner';
import About from './components/About';
import NewStory from './components/NewStory';
import Story from './components/Story';
import PersonalLibrary from './components/PersonalLibrary';
import Profile from './components/Profile';
import Public from './components/Public';
import Navbar from './components/Navbar';
import Signup from './components/Signup';
import Login from './components/Login';
import RequestReset from './components/RequestReset';
import ResetPassword from './components/ResetPassword';
import StoryList from './components/StoryList';
import ClassLibrary from './components/ClassLibrary';
import CreateClass from './components/CreateClass';
import ClassEnrollment from './components/ClassEnrollment';
import ClassDetail from './components/ClassDetail';

// Constants
const API_BASE_URL = process.env.REACT_APP_API_URL || '/api';
const S3_BUCKET_URL = 'https://storybyte1.s3.amazonaws.com';
const FALLBACK_IMAGE = '/static/images/fallback_cover.png';

// Theme configuration
const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
      light: '#424242',
      dark: '#000000',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#FF4F00',
      light: '#FF8A00',
      dark: '#CC3F00',
      contrastText: '#ffffff',
    },
    background: {
      default: '#ffffff',
      paper: '#f5f5f5',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          padding: 0,
          background: 'transparent',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'transparent',
          boxShadow: 'none',
        },
      },
    },
  },
});

interface StoryPreview {
  id: number;
  title: string;
  context: string;
  plays: number;
  likes: number;
  author?: string;
  current_node?: {
    image_url: string;
  };
}

interface ClassPreview {
  id: number;
  name: string;
  description: string;
  students_count: number;
  stories_count: number;
  owner: string;
}

interface User {
  username: string;
}

const AppContent = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!localStorage.getItem('token'));
  const [stories, setStories] = useState<StoryPreview[]>([]);
  const [popularClasses, setPopularClasses] = useState<ClassPreview[]>([]);
  const [myStories, setMyStories] = useState<StoryPreview[]>([]);
  const [myClasses, setMyClasses] = useState<ClassPreview[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [quickTitle, setQuickTitle] = useState("");
  const [user, setUser] = useState<User | null>(null);

  const location = useLocation();
  const isHome = location.pathname === '/';

  useEffect(() => {
    fetchData();
    fetchUserData();
  }, [isAuthenticated]);

  const fetchUserData = async () => {
    if (isAuthenticated) {
      try {
        const response = await axios.get(`${API_BASE_URL}/auth/user/`);
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  };

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      if (isAuthenticated) {
        const [storiesRes, classesRes, myStoriesRes, myClassesRes] = await Promise.all([
          axios.get(`${API_BASE_URL}/stories/popular/`),
          axios.get(`${API_BASE_URL}/classes/popular/`),
          axios.get(`${API_BASE_URL}/stories/personal_library/`),
          axios.get(`${API_BASE_URL}/classes/my_classes/`)
        ]);

        setStories(storiesRes.data);
        setPopularClasses(classesRes.data);
        setMyStories(myStoriesRes.data);
        setMyClasses(myClassesRes.data.owned_classes || []);
      } else {
        const [storiesRes, classesRes] = await Promise.all([
          axios.get(`${API_BASE_URL}/stories/popular/`),
          axios.get(`${API_BASE_URL}/classes/popular/`)
        ]);

        setStories(storiesRes.data);
        setPopularClasses(classesRes.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load content');
    } finally {
      setLoading(false);
    }
  };

  const getImageUrl = (url: string | undefined | null): string => {
    if (!url) return FALLBACK_IMAGE;
    if (url.startsWith('http') || url.startsWith('data:')) return url;
    return `${S3_BUCKET_URL}/${url}`;
  };

  const hasValidImage = (story: StoryPreview): boolean => {
    return !!story.current_node?.image_url && story.current_node.image_url !== FALLBACK_IMAGE;
  };

  const handleLogin = () => {
    setIsAuthenticated(true);
    fetchUserData();
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUser(null);
    setMyStories([]);
    setMyClasses([]);
  };

  const handleAction = (path: string) => {
    window.location.href = path;
  };

  const handleQuickCreate = () => {
    if (quickTitle.trim()) {
      handleAction(`/new?title=${encodeURIComponent(quickTitle.trim())}`);
    }
  };

  const ImageWithFallback: React.FC<{ src: string | undefined | null; alt: string; className?: string }> = ({ src, alt, className }) => {
    const [imgSrc, setImgSrc] = useState(() => getImageUrl(src));

    useEffect(() => {
      setImgSrc(getImageUrl(src));
    }, [src]);

    return (
      <img
        src={imgSrc}
        alt={alt}
        onError={() => setImgSrc(FALLBACK_IMAGE)}
        className={className}
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    );
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      background: 'transparent',
    }}>
      {/* Header */}
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1200,
          background: isHome && !isAuthenticated ? 'transparent' : '#fff',
          marginBottom: isHome && !isAuthenticated ? 0 : '64px',
        }}
      >
        <DiscordBanner />
        <Navbar
          isAuthenticated={isAuthenticated}
          onLogout={handleLogout}
          isTransparent={isHome && !isAuthenticated}
        />
      </Box>

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          paddingTop: isHome && !isAuthenticated ? 0 : '104px',
          minHeight: '100vh',
        }}
      >
        <Routes>
          {/* Home Route */}
          <Route path="/" element={
            isAuthenticated ? (
              <Dashboard
                exploreStories={stories}
                popularClasses={popularClasses}
                myStories={myStories}
                myClasses={myClasses}
                handleAction={handleAction}
                quickTitle={quickTitle}
                setQuickTitle={setQuickTitle}
                handleQuickCreate={handleQuickCreate}
                ImageWithFallback={ImageWithFallback}
              />
            ) : (
              <LandingPage
                validImageStories={stories.filter(hasValidImage)}
                stories={stories}
                popularClasses={popularClasses}
                currentImageIndex={0}
                getImageUrl={getImageUrl}
                loading={loading}
                error={error}
                FALLBACK_IMAGE={FALLBACK_IMAGE}
              />
            )
          } />

          {/* Public routes */}
          <Route path="/about" element={<About />} />
          <Route path="/signup" element={
            <Container maxWidth="lg">
              <Signup onLogin={handleLogin} />
            </Container>
          } />
          <Route path="/public" element={
            isAuthenticated ? (
              <Container maxWidth="lg">
                <Public />
              </Container>
            ) : (
              <Navigate to="/login" state={{ from: '/public' }} />
            )
          } />
          <Route path="/login" element={
            <Container maxWidth="lg">
              <Login onLogin={handleLogin} />
            </Container>
          } />
          <Route path="/forgot-password" element={
            <Container maxWidth="lg">
              <RequestReset />
            </Container>
          } />
          <Route path="/reset-password/:uid/:token" element={
            <Container maxWidth="lg">
              <ResetPassword />
            </Container>
          } />

          {/* Protected Routes */}
          <Route path="/story/:id" element={
            isAuthenticated ? (
              <Container maxWidth="lg">
                <Story />
              </Container>
            ) : (
              <Navigate to="/login" state={{ from: '/story/:id' }} />
            )
          } />
          <Route path="/new" element={
            isAuthenticated ? (
              <Container maxWidth="lg">
                <NewStory />
              </Container>
            ) : (
              <Navigate to="/login" state={{ from: '/new' }} />
            )
          } />
          <Route path="/library" element={
            isAuthenticated ? (
              <Container maxWidth="lg">
                <StoryList />
              </Container>
            ) : (
              <Navigate to="/login" state={{ from: '/library' }} />
            )
          } />
          <Route path="/my-library" element={
            isAuthenticated ? (
              <Container maxWidth="lg">
                <PersonalLibrary />
              </Container>
            ) : (
              <Navigate to="/login" state={{ from: '/my-library' }} />
            )
          } />
          <Route path="/profile/*" element={
            isAuthenticated ? (
              <Container maxWidth="lg">
                <Profile />
              </Container>
            ) : (
              <Navigate to="/login" state={{ from: '/profile' }} />
            )
          } />
          {/* Class Routes */}
          <Route path="/classes" element={
            isAuthenticated ? (
              <Container maxWidth="lg">
                <ClassLibrary />
              </Container>
            ) : (
              <Navigate to="/login" state={{ from: '/classes' }} />
            )
          } />
          <Route path="/classes/create" element={
            isAuthenticated ? (
              <Container maxWidth="lg">
                <CreateClass />
              </Container>
            ) : (
              <Navigate to="/login" state={{ from: '/classes/create' }} />
            )
          } />
          <Route path="/classes/enroll" element={
            isAuthenticated ? (
              <Container maxWidth="lg">
                <ClassEnrollment />
              </Container>
            ) : (
              <Navigate to="/login" state={{ from: '/classes/enroll' }} />
            )
          } />
          <Route path="/classes/:classId" element={
            isAuthenticated ? (
              <Container maxWidth="lg">
                <ClassDetail />
              </Container>
            ) : (
              <Navigate to="/login" state={{ from: '/classes/:classId' }} />
            )
          } />

          {/* Catch-all redirect to home */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Box>
    </Box>
  );
};

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppContent />
      </Router>
    </ThemeProvider>
  );
};

export default App;