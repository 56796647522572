import React from 'react';
import { Box, Typography, Card, CardContent, Grid, styled } from '@mui/material';
import {
  Speed as SpeedIcon,
  Psychology as BrainIcon,
  Diversity3 as DiversityIcon,
  AutoAwesome as CustomizeIcon,
} from '@mui/icons-material';

const benefits = [
  {
    icon: SpeedIcon,
    title: 'Faster Learning',
    description:
      'Interactive storytelling accelerates comprehension through active engagement and personalized pacing',
    gradient: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
  },
  {
    icon: BrainIcon,
    title: 'Better Retention',
    description:
      'Make meaningful choices that reinforce learning and create lasting neural connections',
    gradient: 'linear-gradient(45deg, #FF4F00, #FF8A00)',
  },
  {
    icon: DiversityIcon,
    title: 'Any Level, Any Age',
    description:
      'Sophisticated adaptive content that grows with your knowledge, from basics to advanced topics',
    gradient: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
  },
  {
    icon: CustomizeIcon,
    title: 'Personalized Paths',
    description:
      'Your choices shape the narrative, creating a unique learning experience tailored to your interests',
    gradient: 'linear-gradient(45deg, #FF4F00, #FF8A00)',
  },
];

// Define the types for the custom props
interface AnimatedCardProps {
  index: number;
}

interface IconWrapperProps {
  gradient: string;
}

const StyledBox = styled(Box)(({ theme }) => ({
  maxWidth: '1400px',
  margin: '0 auto',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const BackgroundBox = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  background: 'linear-gradient(135deg, rgba(255,255,255,0.97) 0%, rgba(252,253,254,0.97) 100%)',
  backdropFilter: 'blur(10px)',
  borderRadius: '20px',
  position: 'relative',
  overflow: 'hidden',
  boxShadow: '0 4px 20px rgba(0,0,0,0.06)',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background:
      'radial-gradient(circle at 30% -10%, #FF8A00 0%, transparent 35%), radial-gradient(circle at 70% 110%, #FF4F00 0%, transparent 35%)',
    opacity: 0.05,
    zIndex: 0,
  },
}));

const AnimatedCard = styled(Card)<AnimatedCardProps>(({ theme, index }) => ({
  height: '100%',
  background: 'rgba(255,255,255,0.9)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.spacing(1),
  overflow: 'visible',
  position: 'relative',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  animation: `fadeSlideUp 0.5s ease-out ${index * 0.15}s both`,
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 8px 25px rgba(0,0,0,0.15)',
  },
  '@keyframes fadeSlideUp': {
    '0%': {
      opacity: 0,
      transform: 'translateY(20px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
}));

const IconWrapper = styled(Box)<IconWrapperProps>(({ theme, gradient }) => ({
  position: 'absolute',
  top: -16,
  left: '50%',
  transform: 'translateX(-50%)',
  width: 48,
  height: 48,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: gradient,
  boxShadow: '0 4px 20px rgba(255, 138, 0, 0.3)',
  zIndex: 2,
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'translateX(-50%) scale(1.1)',
  },
}));

const WhyStorybyte = () => {
  return (
    <StyledBox>
      <BackgroundBox>
        <Grid
          container
          spacing={2}
          sx={{
            paddingLeft: { xs: 2, sm: 4 },
            paddingRight: { xs: 2, sm: 4 },
            position: 'relative',
            zIndex: 1,
          }}
        >
          {benefits.map((benefit, index) => {
            const IconComponent = benefit.icon;
            return (
              <Grid item xs={12} sm={6} md={3} key={benefit.title}>
                <AnimatedCard index={index}>
                  <IconWrapper gradient={benefit.gradient}>
                    <IconComponent
                      sx={{
                        color: 'white',
                        fontSize: 24,
                        filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))',
                      }}
                    />
                  </IconWrapper>
                  <CardContent
                    sx={{
                      paddingTop: 4,
                      paddingBottom: '16px !important',
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      paddingLeft: 2.5,
                      paddingRight: 2.5,
                      minHeight: 180,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        marginBottom: 1.5,
                        background: benefit.gradient,
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        fontWeight: 'bold',
                        fontSize: { xs: '1rem', md: '1.1rem' },
                        letterSpacing: '-0.01em',
                      }}
                    >
                      {benefit.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        lineHeight: 1.5,
                        fontSize: { xs: '0.825rem', md: '0.875rem' },
                        color: 'rgba(0,0,0,0.7)',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        maxWidth: '95%',
                      }}
                    >
                      {benefit.description}
                    </Typography>
                  </CardContent>
                </AnimatedCard>
              </Grid>
            );
          })}
        </Grid>
      </BackgroundBox>
    </StyledBox>
  );
};

export default WhyStorybyte;
