import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Container,
  Button,
  Paper,
  Avatar,
} from '@mui/material';
import {
  School as SchoolIcon,
  Psychology as BrainIcon,
  AutoStories as BookIcon,
  BarChart as StatsIcon,
  Speed as SpeedIcon,
  Diversity3 as DiversityIcon,
  AutoAwesome as CustomizeIcon,
  Create as CreateIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface StatsItemProps {
  value: string;
  label: string;
  startValue?: number;
  endValue?: number;
}

const StatsItem: React.FC<StatsItemProps> = ({ value, label }) => {
  const [displayValue, setDisplayValue] = useState('0');
  
  useEffect(() => {
    const numericValue = parseInt(value);
    if (!isNaN(numericValue)) {
      let start = 0;
      const increment = numericValue / 30; // Animate over 30 steps
      const timer = setInterval(() => {
        start += increment;
        if (start >= numericValue) {
          setDisplayValue(value);
          clearInterval(timer);
        } else {
          setDisplayValue(Math.floor(start).toString());
        }
      }, 50);
      return () => clearInterval(timer);
    } else {
      setDisplayValue(value);
    }
  }, [value]);

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="h3" component="div" sx={{ 
        fontWeight: 'bold',
        background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
      }}>
        {displayValue}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary">
        {label}
      </Typography>
    </Box>
  );
};

const About: React.FC = () => {
  const navigate = useNavigate();
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  const features = [
    {
      icon: BookIcon,
      title: "Interactive Learning",
      description: "Engage with stories that adapt to your choices and learning style"
    },
    {
      icon: BrainIcon,
      title: "AI-Enhanced",
      description: "Advanced AI technology that creates personalized educational narratives"
    },
    {
      icon: DiversityIcon,
      title: "Collaborative",
      description: "Connect with teachers and peers in interactive learning spaces"
    },
    {
      icon: CustomizeIcon,
      title: "Personalized",
      description: "Content that adjusts to your knowledge level and interests"
    }
  ];

  const steps = [
    {
      icon: CreateIcon,
      title: "Create",
      description: "Start by crafting your educational story"
    },
    {
      icon: SchoolIcon,
      title: "Learn",
      description: "Engage with interactive content"
    },
    {
      icon: StatsIcon,
      title: "Track",
      description: "Monitor progress and achievements"
    },
    {
      icon: SpeedIcon,
      title: "Improve",
      description: "Accelerate your learning journey"
    }
  ];

  return (
    <Box sx={{ overflow: 'hidden' }}>
      {/* Hero Section */}
      <Box sx={{
        position: 'relative',
        bgcolor: 'transparent',
        color: 'white',
        pt: 15,
        pb: 20,
        background: 'linear-gradient(165deg, #000000 60%, #FF4F00 100%)',
      }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h2" component="h1" 
                sx={{ 
                  fontWeight: 700,
                  mb: 3,
                  animation: animate ? 'fadeSlideUp 0.8s ease-out' : 'none'
                }}>
                Revolutionizing Education Through Stories
              </Typography>
              <Typography variant="h5" sx={{ mb: 4, opacity: 0.9 }}>
                Transform complex concepts into engaging narratives that make learning stick.
              </Typography>
              <Button
                variant="contained"
                size="large"
                onClick={() => navigate('/new')}
                sx={{
                  background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
                  px: 4,
                  py: 2,
                  fontSize: '1.1rem',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #FF9500, #FF5F00)',
                  }
                }}
              >
                Start Creating
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Stats Section */}
      <Container maxWidth="lg" sx={{ mt: -10, mb: 8, position: 'relative' }}>
        <Paper elevation={3} sx={{ py: 6, px: 4, borderRadius: 4 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={3}>
              <StatsItem value="85" label="% Higher Retention" />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatsItem value="2.5" label="x Faster Learning" />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatsItem value="1000" label="+ Active Stories" />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatsItem value="93" label="% Student Satisfaction" />
            </Grid>
          </Grid>
        </Paper>
      </Container>

      {/* Features Section */}
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Typography variant="h3" component="h2" align="center" gutterBottom>
          Why Choose Storybyte
        </Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={feature.title}>
              <Card sx={{ 
                height: '100%',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-8px)'
                }
              }}>
                <CardContent sx={{ textAlign: 'center' }}>
                  <Avatar sx={{ 
                    bgcolor: 'transparent',
                    margin: '0 auto',
                    width: 60,
                    height: 60,
                    mb: 2,
                    background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
                  }}>
                    <feature.icon sx={{ fontSize: 30 }} />
                  </Avatar>
                  <Typography variant="h6" component="h3" gutterBottom>
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* How It Works Section */}
      <Box sx={{ bgcolor: 'grey.50', py: 8 }}>
        <Container maxWidth="lg">
          <Typography variant="h3" component="h2" align="center" gutterBottom>
            How It Works
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            {steps.map((step, index) => (
              <Grid item xs={12} sm={6} md={3} key={step.title}>
                <Box sx={{ textAlign: 'center' }}>
                  <Avatar sx={{ 
                    bgcolor: 'transparent',
                    margin: '0 auto',
                    width: 60,
                    height: 60,
                    mb: 2,
                    background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
                  }}>
                    <step.icon />
                  </Avatar>
                  <Typography variant="h6" gutterBottom>
                    {step.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {step.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Call to Action */}
      <Box sx={{ 
        bgcolor: 'black', 
        color: 'white',
        py: 8,
        textAlign: 'center'
      }}>
        <Container maxWidth="sm">
          <Typography variant="h4" component="h2" gutterBottom>
            Ready to Transform Learning?
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Join thousands of educators and learners who are already using Storybyte to make education more engaging and effective.
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate('/signup')}
            sx={{
              background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
              px: 4,
              py: 2,
              '&:hover': {
                background: 'linear-gradient(45deg, #FF9500, #FF5F00)',
              }
            }}
          >
            Get Started Now
          </Button>
        </Container>
      </Box>
    </Box>
  );
};

export default About;