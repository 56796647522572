import React, { useState, useMemo } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { 
  Typography, 
  Button, 
  Box, 
  TextField, 
  Divider, 
  Paper,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  Check as CheckIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { setupAxios } from '../utils/auth';
import { API_BASE_URL } from '../config';

interface SignupProps {
  onLogin: () => void;
}

interface PasswordRequirement {
  regex: RegExp;
  message: string;
}

const passwordRequirements: PasswordRequirement[] = [
  { regex: /.{8,}/, message: 'At least 8 characters long' },
  { regex: /[A-Z]/, message: 'Contains uppercase letter' },
  { regex: /[a-z]/, message: 'Contains lowercase letter' },
  { regex: /[0-9]/, message: 'Contains number' },
  { regex: /[^A-Za-z0-9]/, message: 'Contains special character' },
];

const Signup: React.FC<SignupProps> = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [tosAccepted, setTosAccepted] = useState(false);
  const [tosDialogOpen, setTosDialogOpen] = useState(false);
  const navigate = useNavigate();

  const passwordStrength = useMemo(() => {
    return passwordRequirements.filter(req => req.regex.test(password)).length;
  }, [password]);

  const passwordStrengthPercent = (passwordStrength / passwordRequirements.length) * 100;

  const getStrengthColor = () => {
    if (passwordStrengthPercent <= 20) return 'error';
    if (passwordStrengthPercent <= 60) return 'warning';
    return 'success';
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    
    if (!tosAccepted) {
      setError('Please accept the Terms of Service to continue');
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    if (passwordStrength < passwordRequirements.length) {
      setError('Please meet all password requirements');
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/accounts/signup/`, {
        username,
        email,
        password
      });
      
      localStorage.setItem('token', response.data.token);
      setupAxios();
      onLogin();
      navigate('/');
    } catch (error) {
      console.error('Full error object:', error);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          setError(error.response.data.detail || 'An error occurred during signup');
        } else if (error.request) {
          setError('No response received from the server. Please try again.');
        } else {
          setError('Error setting up the request. Please try again.');
        }
      } else {
        setError('An unexpected error occurred');
      }
    }
  };

  const TermsOfService = () => (
    <Dialog 
      open={tosDialogOpen} 
      onClose={() => setTosDialogOpen(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ fontWeight: 600 }}>Terms of Service - Storybyte AI Learning Platform</DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6" gutterBottom>1. Service Description</Typography>
        <Typography paragraph>
          Storybyte is an AI-powered educational platform that provides interactive learning experiences through storytelling and adaptive content generation.
        </Typography>

        <Typography variant="h6" gutterBottom>2. AI-Generated Content</Typography>
        <Typography paragraph>
          • Content may be generated using artificial intelligence technologies
          • We do not guarantee the accuracy or completeness of AI-generated content
          • Users should exercise critical thinking and verify important information
        </Typography>

        <Typography variant="h6" gutterBottom>3. Data Collection and Privacy</Typography>
        <Typography paragraph>
          • We collect user interaction data to improve learning experiences
          • Personal information is protected and never sold to third parties
          • Learning patterns are analyzed to enhance educational outcomes
        </Typography>

        <Typography variant="h6" gutterBottom>4. Child Safety and COPPA Compliance</Typography>
        <Typography paragraph>
          • Users under 13 require parental consent
          • Content is filtered for age-appropriateness
          • Enhanced privacy protections for young learners
        </Typography>

        <Typography variant="h6" gutterBottom>5. Intellectual Property</Typography>
        <Typography paragraph>
          • User-generated content remains the property of the user
          • AI-generated content is licensed for educational use only
          • Sharing and modification of content must comply with fair use
        </Typography>

        <Typography variant="h6" gutterBottom>6. Usage Guidelines</Typography>
        <Typography paragraph>
          • Content should be used for educational purposes only
          • No unauthorized distribution of platform materials
          • Respect intellectual property rights of others
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setTosDialogOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box 
      sx={{ 
        mt: { xs: 12, sm: 14 },
        mx: 'auto',
        px: 2,
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Paper 
        elevation={3}
        sx={{
          p: 4,
          maxWidth: 400,
          width: '100%',
          borderRadius: 2,
          background: 'rgba(255, 255, 255, 0.98)',
          backdropFilter: 'blur(10px)'
        }}
      >
        <Typography 
          variant="h4" 
          component="h1" 
          gutterBottom
          sx={{
            fontWeight: 600,
            textAlign: 'center',
            mb: 3,
            color: '#1a1a1a'
          }}
        >
          Create Account
        </Typography>

        {error && (
          <Typography 
            color="error" 
            sx={{ 
              mb: 2,
              textAlign: 'center',
              backgroundColor: 'rgba(211, 47, 47, 0.1)',
              p: 1,
              borderRadius: 1
            }}
          >
            {error}
          </Typography>
        )}

        <Box 
          component="form" 
          onSubmit={handleSubmit}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <TextField
            fullWidth
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            autoComplete="username"
            sx={{
              '& .MuiOutlinedInput-root': {
                bgcolor: 'white'
              }
            }}
          />

          <TextField
            fullWidth
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            autoComplete="email"
            sx={{
              '& .MuiOutlinedInput-root': {
                bgcolor: 'white'
              }
            }}
          />

          <Box>
            <TextField
              fullWidth
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="new-password"
              sx={{
                '& .MuiOutlinedInput-root': {
                  bgcolor: 'white'
                }
              }}
            />
            <LinearProgress 
              variant="determinate" 
              value={passwordStrengthPercent}
              color={getStrengthColor()}
              sx={{ mt: 1, borderRadius: 1 }}
            />
            <List dense>
              {passwordRequirements.map((req, index) => (
                <ListItem key={index} dense>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    {req.regex.test(password) ? (
                      <CheckIcon color="success" fontSize="small" />
                    ) : (
                      <CloseIcon color="error" fontSize="small" />
                    )}
                  </ListItemIcon>
                  <ListItemText 
                    primary={req.message} 
                    primaryTypographyProps={{ 
                      variant: 'caption',
                      color: req.regex.test(password) ? 'success.main' : 'error' 
                    }} 
                  />
                </ListItem>
              ))}
            </List>
          </Box>

          <TextField
            fullWidth
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            autoComplete="new-password"
            error={confirmPassword !== '' && password !== confirmPassword}
            helperText={confirmPassword !== '' && password !== confirmPassword ? "Passwords don't match" : ''}
            sx={{
              '& .MuiOutlinedInput-root': {
                bgcolor: 'white'
              }
            }}
          />

          <Box sx={{ mt: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={tosAccepted}
                  onChange={(e) => setTosAccepted(e.target.checked)}
                  required
                />
              }
              label={
                <Typography variant="body2">
                  I accept the{' '}
                  <Button 
                    onClick={() => setTosDialogOpen(true)}
                    sx={{ 
                      p: 0, 
                      textTransform: 'none',
                      textDecoration: 'underline',
                      verticalAlign: 'baseline',
                      fontWeight: 'normal',
                      minWidth: 0,
                      color: 'primary.main'
                    }}
                  >
                    Terms of Service
                  </Button>
                </Typography>
              }
            />
          </Box>

          <Button 
            type="submit" 
            variant="contained" 
            fullWidth
            sx={{ 
              py: 1.5,
              fontSize: '1rem',
              fontWeight: 500,
              textTransform: 'none',
              borderRadius: 1.5,
              bgcolor: '#1a1a1a',
              '&:hover': {
                bgcolor: '#333'
              }
            }}
          >
            Sign Up
          </Button>

          <Divider sx={{ my: 2 }}>
            <Typography variant="body2" color="text.secondary">
              OR
            </Typography>
          </Divider>

          <Button
            component={RouterLink}
            to="/login"
            variant="outlined"
            fullWidth
            sx={{ 
              py: 1.5,
              fontSize: '1rem',
              fontWeight: 500,
              textTransform: 'none',
              borderRadius: 1.5,
              borderColor: '#1a1a1a',
              color: '#1a1a1a',
              '&:hover': {
                borderColor: '#333',
                bgcolor: 'rgba(26, 26, 26, 0.04)'
              }
            }}
          >
            Already have an account? Log in here
          </Button>
        </Box>
      </Paper>
      <TermsOfService />
    </Box>
  );
};

export default Signup;