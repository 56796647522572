import React from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  TextField,
  Chip,
  Avatar,
  useTheme,
} from "@mui/material";
import {
  Create,
  TrendingUp,
  School,
  People,
  MenuBook,
  ArrowForward,
} from "@mui/icons-material";

interface DashboardProps {
  popularClasses: Array<{
    id: number;
    name: string;
    description: string;
    students_count: number;
    stories_count: number;
    owner: string;
  }>;
  exploreStories: Array<{
    id: number;
    title: string;
    context: string;
    plays: number;
    likes: number;
    author?: string;
    current_node?: {
      image_url: string;
    };
  }>;
  myClasses: Array<{
    id: number;
    name: string;
    description: string;
    students_count: number;
    stories_count: number;
    owner: string;
  }>;
  myStories: Array<{
    id: number;
    title: string;
    context: string;
    plays: number;
    likes: number;
    author?: string;
    current_node?: {
      image_url: string;
    };
  }>;
  handleAction: (path: string) => void;
  quickTitle: string;
  setQuickTitle: (title: string) => void;
  handleQuickCreate: () => void;
  ImageWithFallback: React.FC<{
    src: string | undefined | null;
    alt: string;
    className?: string;
  }>;
}

const Dashboard: React.FC<DashboardProps> = ({
    popularClasses,
    exploreStories,
    myClasses,
    myStories,
    handleAction,
    quickTitle,
    setQuickTitle,
    handleQuickCreate,
    ImageWithFallback,
  }) => {
  const theme = useTheme();

  const QuickStartBackground = () => (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: "hidden",
        "&::before": {
          content: '""',
          position: "absolute",
          top: "-50%",
          left: "-50%",
          right: "-50%",
          bottom: "-50%",
          background: "radial-gradient(circle, rgba(255,138,0,0.1) 0%, rgba(255,79,0,0.1) 100%)",
          animation: "rotate 20s linear infinite",
        },
        "&::after": {
          content: '""',
          position: "absolute",
          top: "-50%",
          left: "-50%",
          right: "-50%",
          bottom: "-50%",
          background: "radial-gradient(circle, rgba(255,138,0,0.05) 0%, rgba(255,79,0,0.05) 100%)",
          animation: "rotate 30s linear infinite reverse",
        },
        "@keyframes rotate": {
          "0%": {
            transform: "rotate(0deg)",
          },
          "100%": {
            transform: "rotate(360deg)",
          },
        },
      }}
    />
  );

  const renderClassGrid = (classes: DashboardProps['popularClasses'], title: string) => (
    <Box sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
        {title}
      </Typography>
      <Grid 
        container 
        spacing={4} 
        sx={{ 
          maxWidth: "1400px", 
          mx: "auto" 
        }}
      >
        {classes.map((classItem) => (
          <Grid 
            item 
            xs={6}
            md={4} 
            lg={3}
            key={classItem.id}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                borderRadius: 4,
                maxWidth: "320px",
                width: "100%",
              }}
            >
              <CardActionArea
                onClick={() => handleAction(`/classes/${classItem.id}`)}
                sx={{ flexGrow: 1 }}
              >
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {classItem.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, height: 60, overflow: "hidden" }}
                  >
                    {classItem.description}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Chip
                      avatar={<Avatar>{classItem.owner[0]}</Avatar>}
                      label={classItem.owner}
                      variant="outlined"
                      sx={{ borderRadius: 4 }}
                    />
                    <Box>
                      <Chip
                        icon={<People />}
                        label={`${classItem.students_count}`}
                        size="small"
                        sx={{ mr: 1, borderRadius: 4 }}
                      />
                      <Chip
                        icon={<MenuBook />}
                        label={`${classItem.stories_count}`}
                        size="small"
                        sx={{ borderRadius: 4 }}
                      />
                    </Box>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  const renderStoryGrid = (stories: DashboardProps['exploreStories'], title: string) => (
    <Box sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
        {title}
      </Typography>
      <Grid 
        container 
        spacing={4} 
        sx={{ 
          maxWidth: "1400px", 
          mx: "auto" 
        }}
      >
        {stories.map((story) => (
          <Grid 
            item 
            xs={6}
            md={4} 
            lg={3}
            key={story.id}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                borderRadius: 4,
                maxWidth: "320px",
                width: "100%",
              }}
            >
              <CardActionArea
                onClick={() => handleAction(`/story/${story.id}`)}
              >
                <Box sx={{ height: 140, overflow: 'hidden' }}>
                  <ImageWithFallback
                    src={story.current_node?.image_url || '/static/images/fallback_cover.png'}
                    alt={story.title}
                    className="story-cover"
                  />
                </Box>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="div">
                    {story.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2 }}
                  >
                    {story.context.substring(0, 100)}...
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Chip
                      avatar={<Avatar>{story.author?.[0] || "A"}</Avatar>}
                      label={story.author || "Anonymous"}
                      variant="outlined"
                      sx={{ borderRadius: 4 }}
                    />
                    <Box>
                      <Chip
                        icon={<TrendingUp />}
                        label={`${story.plays}`}
                        size="small"
                        sx={{ mr: 1, borderRadius: 4 }}
                      />
                      <Chip
                        icon={<School />}
                        label={`${story.likes}`}
                        size="small"
                        sx={{ borderRadius: 4 }}
                      />
                    </Box>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  return (
    <Box sx={{ mt: 4, px: 4 }}>
      {/* Quick Start Section */}
      <Box
        sx={{
          py: 6,
          px: { xs: 2, sm: 4 },
          bgcolor: "background.paper",
          borderRadius: 4,
          boxShadow: (theme) =>
            `0 8px 32px ${theme.palette.mode === "dark" ? "rgba(0,0,0,0.2)" : "rgba(0,0,0,0.1)"}`,
          maxWidth: "900px",
          mx: "auto",
          textAlign: "center",
          position: "relative",
          overflow: "hidden",
          mb: 4,
        }}
      >
        <QuickStartBackground />
        <Box sx={{ position: "relative", zIndex: 1 }}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{
              fontWeight: 600,
              background: "linear-gradient(45deg, #FF8A00, #FF4F00)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              letterSpacing: "-0.02em",
            }}
          >
            Quick Start Your Story
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              maxWidth: "600px",
              mx: "auto",
              mb: 4,
              lineHeight: 1.6,
            }}
          >
            Jump right in and create your first interactive story. Enter a title and get started in seconds!
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
              mt: 2,
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Enter your story title"
              value={quickTitle}
              onChange={(e) => setQuickTitle(e.target.value)}
              sx={{
                width: { xs: "100%", sm: "70%" },
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                  bgcolor: "background.paper",
                  boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    boxShadow: "0 6px 15px rgba(0,0,0,0.15)",
                  },
                },
              }}
            />
            <Button
              variant="contained"
              onClick={handleQuickCreate}
              disabled={!quickTitle.trim()}
              sx={{
                py: 1.5,
                px: 3,
                fontSize: "1rem",
                borderRadius: 2,
                background: "linear-gradient(45deg, #FF8A00, #FF4F00)",
                color: "white",
                fontWeight: 600,
                boxShadow: "0 4px 15px rgba(255, 138, 0, 0.3)",
                transition: "all 0.3s ease",
                "&:hover": {
                  background: "linear-gradient(45deg, #FF9500, #FF5F00)",
                  boxShadow: "0 6px 20px rgba(255, 138, 0, 0.4)",
                  transform: "translateY(-2px)",
                },
                "&:disabled": {
                  background: "rgba(255, 138, 0, 0.5)",
                  color: "#fff",
                },
              }}
            >
              Create
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Quick Actions */}
      <Box sx={{ py: 4 }}>
        <Grid container spacing={3} sx={{ maxWidth: "1400px", mx: "auto", mb: 4 }}>
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                height: "100%",
                background: "linear-gradient(45deg, #FF8A00, #FF4F00)",
                color: "white",
                borderRadius: 4,
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "translateY(-4px)",
                  boxShadow: "0 12px 28px rgba(255, 138, 0, 0.2)",
                },
              }}
            >
              <CardActionArea
                onClick={() => handleAction("/new")}
                sx={{
                  height: "100%",
                  "&:hover .arrow-icon": {
                    transform: "translateX(4px)",
                  },
                }}
              >
                <CardContent sx={{ p: 4, position: "relative" }}>
                  <Create sx={{ fontSize: 48, mb: 2, opacity: 0.9 }} />
                  <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
                    Create New Story
                  </Typography>
                  <Typography variant="body1" sx={{ opacity: 0.9, mb: 2 }}>
                    Start crafting your interactive educational story with our AI-powered platform
                  </Typography>
                  <ArrowForward
                    className="arrow-icon"
                    sx={{
                      position: "absolute",
                      bottom: 24,
                      right: 24,
                      transition: "transform 0.3s ease",
                    }}
                  />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                height: "100%",
                background: "linear-gradient(45deg, #2196F3, #21CBF3)",
                color: "white",
                borderRadius: 4,
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "translateY(-4px)",
                  boxShadow: "0 12px 28px rgba(33, 150, 243, 0.2)",
                },
              }}
            >
              <CardActionArea
                onClick={() => handleAction("/classes/create")}
                sx={{
                  height: "100%",
                  "&:hover .arrow-icon": {
                    transform: "translateX(4px)",
                  },
                }}
              >
                <CardContent sx={{ p: 4, position: "relative" }}>
                <School sx={{ fontSize: 48, mb: 2, opacity: 0.9 }} />
                  <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
                    Create New Class
                  </Typography>
                  <Typography variant="body1" sx={{ opacity: 0.9, mb: 2 }}>
                    Set up a new learning space for your students or study group
                  </Typography>
                  <ArrowForward
                    className="arrow-icon"
                    sx={{
                      position: "absolute",
                      bottom: 24,
                      right: 24,
                      transition: "transform 0.3s ease",
                    }}
                  />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Popular Classes */}
      {renderClassGrid(popularClasses, "Popular Classes")}

      {/* Explore Stories */}
      {renderStoryGrid(exploreStories, "Explore Stories")}

      {/* My Classes */}
      {renderClassGrid(myClasses, "My Classes")}

      {/* My Stories */}
      {renderStoryGrid(myStories, "My Stories")}

      {/* Learning Progress Section */}
      <Box
        sx={{
          py: 4,
          px: 4,
          bgcolor: "background.paper",
          borderRadius: 4,
          mt: 4,
          boxShadow: (theme) =>
            `0 8px 32px ${
              theme.palette.mode === "dark" ? "rgba(0,0,0,0.2)" : "rgba(0,0,0,0.1)"
            }`,
          maxWidth: "1400px",
          mx: "auto",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{ mb: 4, fontWeight: 600 }}
        >
          Your Learning Progress
        </Typography>
        <Grid container spacing={4} sx={{ mt: 2 }}>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                textAlign: "center",
                transition: "transform 0.3s ease",
                "&:hover": {
                  transform: "translateY(-4px)",
                },
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  background: "linear-gradient(45deg, #FF8A00, #FF4F00)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontWeight: 700,
                  mb: 1,
                }}
              >
                {myStories.length}
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                sx={{ fontWeight: 500 }}
              >
                Stories Created
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                textAlign: "center",
                transition: "transform 0.3s ease",
                "&:hover": {
                  transform: "translateY(-4px)",
                },
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  background: "linear-gradient(45deg, #2196F3, #21CBF3)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontWeight: 700,
                  mb: 1,
                }}
              >
                {myStories.reduce((acc, story) => acc + story.plays, 0)}
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                sx={{ fontWeight: 500 }}
              >
                Total Plays
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                textAlign: "center",
                transition: "transform 0.3s ease",
                "&:hover": {
                  transform: "translateY(-4px)",
                },
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  background: "linear-gradient(45deg, #00C853, #69F0AE)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontWeight: 700,
                  mb: 1,
                }}
              >
                {myStories.reduce((acc, story) => acc + story.likes, 0)}
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                sx={{ fontWeight: 500 }}
              >
                Total Likes
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;