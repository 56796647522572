import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Button,
  TextField,
  Grid,
  Chip,
  Container,
  Card,
  CardContent,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch,
  Tabs,
  Tab,
  IconButton,
  Collapse,
  Paper,
} from "@mui/material";
import {
  Lock as LockIcon,
  Public as PublicIcon,
  ContentCopy as CopyIcon,
  LibraryBooks as LibraryIcon,
  Share as ShareIcon,
  Add as AddIcon,
  ExpandMore as ExpandMoreIcon,
  Close as CloseIcon,
  School as SchoolIcon,
} from "@mui/icons-material";

interface Class {
  id: number;
  name: string;
  description: string;
  class_code: string;
  stories_count: number;
  students_count: number;
  is_public: boolean;
  owner: string;
}

interface ApiResponse {
  owned_classes: Class[];
  enrolled_classes: Class[];
}

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';

const ClassLibrary: React.FC = () => {
  // State management
  const [activeTab, setActiveTab] = useState(0);
  const [ownedClasses, setOwnedClasses] = useState<Class[]>([]);
  const [enrolledClasses, setEnrolledClasses] = useState<Class[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [enrollExpanded, setEnrollExpanded] = useState(false);

  // Create class form state
  const [newClassName, setNewClassName] = useState("");
  const [newClassDescription, setNewClassDescription] = useState("");
  const [newClassIsPublic, setNewClassIsPublic] = useState(false);

  // Enroll form state
  const [enrollClassCode, setEnrollClassCode] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchClasses = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get<ApiResponse>(
        `${API_BASE_URL}/classes/my_classes/`
      );
      setOwnedClasses(response.data.owned_classes || []);
      setEnrolledClasses(response.data.enrolled_classes || []);
    } catch (error) {
      console.error("Error fetching classes:", error);
      setError("Failed to fetch classes. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCreateClass = async () => {
    if (!newClassName.trim()) return;

    setLoading(true);
    setError(null);
    try {
      await axios.post(`${API_BASE_URL}/classes/`, {
        name: newClassName,
        description: newClassDescription,
        is_public: newClassIsPublic,
      });
      await fetchClasses();
      setCreateDialogOpen(false);
      setSnackbar({ open: true, message: "Class created successfully!" });
      resetCreateForm();
    } catch (error) {
      console.error("Error creating class:", error);
      setError("Failed to create class. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleEnrollClass = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!enrollClassCode.trim()) return;

    setLoading(true);
    setError(null);
    try {
      await axios.post(`${API_BASE_URL}/classes/${enrollClassCode}/enroll/`);
      await fetchClasses();
      setSnackbar({ open: true, message: "Successfully enrolled in class!" });
      setEnrollClassCode("");
      setEnrollExpanded(false);
    } catch (error) {
      console.error("Error enrolling in class:", error);
      setError("Failed to enroll in class. Please check the code and try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCopyClassCode = (classCode: string) => {
    navigator.clipboard.writeText(classCode);
    setSnackbar({ open: true, message: "Class code copied!" });
  };

  const handleShare = async (class_: Class) => {
    try {
      // Create the share URL using the class ID
      const shareUrl = `${window.location.origin}/classes/${class_.id}`;
      const shareText = `Join my class "${class_.name}" on Storybyte!`;

      if (navigator.share) {
        await navigator.share({
          title: `Join ${class_.name}`,
          text: shareText,
          url: shareUrl
        });
      }

      // Always copy to clipboard, even if native sharing is available
      await navigator.clipboard.writeText(`${shareText}\n${shareUrl}\nClass code: ${class_.class_code}`);
      setSnackbar({ open: true, message: "Link copied to clipboard!" });

    } catch (error) {
      console.error('Error sharing:', error);
      setSnackbar({ open: true, message: "Couldn't share class. Please try again." });
    }
  };


  const resetCreateForm = () => {
    setNewClassName("");
    setNewClassDescription("");
    setNewClassIsPublic(false);
  };

  const ClassCard: React.FC<{ class_: Class; isOwned: boolean }> = ({ class_, isOwned }) => (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
        },
      }}
    >
      <CardContent sx={{ p: 3, flexGrow: 1 }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
            {class_.name}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {class_.description || "No description provided"}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            <Chip
              icon={<SchoolIcon sx={{ fontSize: 16 }} />}
              label={`${class_.students_count} Students`}
              size="small"
            />
            <Chip
              icon={<LibraryIcon sx={{ fontSize: 16 }} />}
              label={`${class_.stories_count} Stories`}
              size="small"
            />
            <Chip
              icon={class_.is_public ? <PublicIcon sx={{ fontSize: 16 }} /> : <LockIcon sx={{ fontSize: 16 }} />}
              label={class_.is_public ? "Public" : "Private"}
              color={class_.is_public ? "success" : "default"}
              size="small"
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {isOwned && (
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button
                  size="small"
                  startIcon={<CopyIcon />}
                  onClick={() => handleCopyClassCode(class_.class_code)}
                >
                  {class_.class_code}
                </Button>
                <Button
                  size="small"
                  startIcon={<ShareIcon />}
                  onClick={() => handleShare(class_)}
                  sx={{
                    minWidth: '80px', // Ensure consistent width
                  }}
                >
                  Share
                </Button>
              </Box>
            )}
            <Button
              variant="contained"
              size="small"
              onClick={() => navigate(`/classes/${class_.id}`)}
              sx={{
                bgcolor: '#1a1a1a',
                '&:hover': { bgcolor: '#333' },
              }}
            >
              View Class
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );

  if (loading && !ownedClasses.length && !enrolledClasses.length) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="85vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Header Section */}
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          My Classes
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="outlined"
            onClick={() => setEnrollExpanded(!enrollExpanded)}
            startIcon={enrollExpanded ? <CloseIcon /> : <SchoolIcon />}
            sx={{
              borderColor: '#1a1a1a',
              color: '#1a1a1a',
              '&:hover': { borderColor: '#333', bgcolor: 'rgba(0,0,0,0.05)' },
            }}
          >
            Join Class
          </Button>
          <Button
            variant="contained"
            onClick={() => setCreateDialogOpen(true)}
            startIcon={<AddIcon />}
            sx={{
              bgcolor: '#1a1a1a',
              '&:hover': { bgcolor: '#333' },
            }}
          >
            Create Class
          </Button>
        </Box>
      </Box>

      {/* Enroll Section */}
      <Collapse in={enrollExpanded}>
        <Paper sx={{ p: 3, mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Join a Class</Typography>
          <Box component="form" onSubmit={handleEnrollClass} sx={{ display: 'flex', gap: 2 }}>
            <TextField
              fullWidth
              size="small"
              placeholder="Enter class code"
              value={enrollClassCode}
              onChange={(e) => setEnrollClassCode(e.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              disabled={!enrollClassCode.trim() || loading}
              sx={{
                bgcolor: '#1a1a1a',
                '&:hover': { bgcolor: '#333' },
              }}
            >
              Join
            </Button>
          </Box>
        </Paper>
      </Collapse>

      {error && (
        <Alert severity="error" onClose={() => setError(null)} sx={{ mb: 4 }}>
          {error}
        </Alert>
      )}

      {/* Tabs Navigation */}
      <Tabs
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue)}
        sx={{ mb: 4, borderBottom: 1, borderColor: 'divider' }}
      >
        <Tab label={`Classes You Own (${ownedClasses.length})`} />
        <Tab label={`Enrolled Classes (${enrolledClasses.length})`} />
      </Tabs>

      {/* Classes Grid */}
      <Grid container spacing={3}>
        {(activeTab === 0 ? ownedClasses : enrolledClasses).map((class_) => (
          <Grid item xs={12} sm={6} lg={4} key={class_.id}>
            <ClassCard class_={class_} isOwned={activeTab === 0} />
          </Grid>
        ))}
      </Grid>

      {/* Create Class Dialog */}
      <Dialog open={createDialogOpen} onClose={() => setCreateDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Create a New Class</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Class Name"
            fullWidth
            value={newClassName}
            onChange={(e) => setNewClassName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            multiline
            rows={3}
            value={newClassDescription}
            onChange={(e) => setNewClassDescription(e.target.value)}
          />
          <FormControlLabel
            control={
              <Switch
                checked={newClassIsPublic}
                onChange={(e) => setNewClassIsPublic(e.target.checked)}
              />
            }
            label="Make class public"
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleCreateClass}
            variant="contained"
            disabled={!newClassName.trim() || loading}
            sx={{
              bgcolor: '#1a1a1a',
              '&:hover': { bgcolor: '#333' },
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Container>
  );
};

export default ClassLibrary;