import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { 
  TextField, 
  Button, 
  Typography, 
  Box, 
  CircularProgress, 
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControlLabel,
  Switch,
  Alert,
  Snackbar,
  Paper
} from '@mui/material';
import axios from 'axios';

interface Class {
  id: number;
  name: string;
  stories_count: number;
  owner: string;
}

interface StoryData {
  title: string;
  context: string;
  education_level: string;
  class_id: number | null;
  is_public: boolean;
  is_educational: boolean;
}

const educationLevels = [
  "General",
  "Elementary",
  "Middle School",
  "High School",
  "Undergraduate",
  "Graduate",
  "Professional"
];

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';

const NewStory: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [title, setTitle] = useState('');
  const [context, setContext] = useState('');
  const [educationLevel, setEducationLevel] = useState('General');
  const [classId, setClassId] = useState<string>('');
  const [classes, setClasses] = useState<Class[]>([]);
  const [isPublic, setIsPublic] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' as 'success' | 'error' });
  const navigate = useNavigate();

  useEffect(() => {
    const urlTitle = searchParams.get('title');
    if (urlTitle) {
      setTitle(decodeURIComponent(urlTitle));
    }
    fetchClasses();
  }, [searchParams]);

  const fetchClasses = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/classes/my_classes/`);
      // Only use owned classes, not enrolled classes
      const ownedClasses = response.data.owned_classes;
      setClasses(ownedClasses);
    } catch (error) {
      console.error('Error fetching classes:', error);
      setSnackbar({ open: true, message: 'Failed to fetch classes. Please try again.', severity: 'error' });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    const storyData: StoryData = {
      title,
      context,
      education_level: educationLevel,
      class_id: classId ? parseInt(classId) : null,
      is_public: classId ? false : isPublic,
      is_educational: true // Always set to true since we removed the toggle
    };

    try {
      const response = await axios.post(`${API_BASE_URL}/stories/`, storyData);
      setSnackbar({ open: true, message: 'Story created successfully!', severity: 'success' });
      setTimeout(() => navigate(`/story/${response.data.id}`), 2000);
    } catch (error) {
      console.error('Error creating new story:', error);
      if (axios.isAxiosError(error) && error.response) {
        setError(`Failed to create new story: ${error.response.data.error || error.response.data.detail || error.response.statusText}`);
      } else {
        setError('Failed to create new story. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    if (title.trim() === '') {
      setError('Title is required');
      return false;
    }
    if (context.trim() === '') {
      setError('Context is required');
      return false;
    }
    return true;
  };

  const handleEducationLevelChange = (event: SelectChangeEvent) => {
    setEducationLevel(event.target.value as string);
  };

  const handleClassChange = (event: SelectChangeEvent) => {
    setClassId(event.target.value);
    if (event.target.value) {
      setIsPublic(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading && !classes.length) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Create New Story
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Context"
            multiline
            rows={4}
            value={context}
            onChange={(e) => setContext(e.target.value)}
            margin="normal"
            required
            placeholder="Describe the setting and main themes of your story"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="education-level-label">Education Level</InputLabel>
            <Select
              labelId="education-level-label"
              id="education-level"
              value={educationLevel}
              label="Education Level"
              onChange={handleEducationLevelChange}
              required
            >
              {educationLevels.map((level) => (
                <MenuItem key={level} value={level}>
                  {level}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="class-label">Class (Optional)</InputLabel>
            <Select
              labelId="class-label"
              id="class"
              value={classId}
              label="Class"
              onChange={handleClassChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {classes.map((class_) => (
                <MenuItem key={class_.id} value={class_.id.toString()}>
                  {class_.name} - {class_.stories_count} stories
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!classId && (
            <FormControlLabel
              control={
                <Switch
                  checked={isPublic}
                  onChange={(e) => setIsPublic(e.target.checked)}
                  name="isPublic"
                />
              }
              label="Make story public"
            />
          )}
          <Button 
            type="submit" 
            variant="contained" 
            sx={{ 
              mt: 2,
              bgcolor: 'black',
              color: 'white',
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.8)',
              },
            }} 
            disabled={loading}
          >
            Create Story
          </Button>
        </Box>
      </Paper>
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NewStory;