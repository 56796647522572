import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Typography,
    Button,
    Box,
    Grid,
    Card,
    CardContent,
    CardActionArea,
    CircularProgress,
    Chip,
    Avatar,
    Alert,
    Container,
} from "@mui/material";
import {
    Create,
    Explore,
    TrendingUp,
    School,
    People,
    MenuBook,
    KeyboardArrowDown,
} from "@mui/icons-material";
import WhyStorybyte from './WhyStorybyte';
import RoadmapSection from './RoadmapSection';

const S3_BUCKET_URL = 'https://storybyte1.s3.amazonaws.com';
const FALLBACK_IMAGE = '/static/images/fallback_cover.png';

interface StoryPreview {
    id: number;
    title: string;
    context: string;
    plays: number;
    likes: number;
    author?: string;
    current_node?: {
        image_url: string;
    };
}

interface ClassPreview {
    id: number;
    name: string;
    description: string;
    students_count: number;
    stories_count: number;
    owner: string;
}

interface LandingPageProps {
    validImageStories: StoryPreview[];
    stories: StoryPreview[];
    popularClasses: ClassPreview[];
    currentImageIndex: number;
    getImageUrl: (url: string | undefined | null) => string;
    loading: boolean;
    error: string | null;
    FALLBACK_IMAGE: string;
}

interface HeroImageProps {
    src: string | undefined | null;
    alt: string;
    className?: string;
}

interface HeroImageState {
    currentIndex: number;
    nextIndex: number;
    isTransitioning: boolean;
}

const keyframes = {
    '@keyframes fadeInUp': {
        from: { opacity: 0, transform: 'translateY(20px)' },
        to: { opacity: 1, transform: 'translateY(0)' }
    },
    '@keyframes scrollDown': {
        '0%': { transform: 'translateY(0)' },
        '50%': { transform: 'translateY(10px)' },
        '100%': { transform: 'translateY(0)' }
    }
};

const SECTION_SPACING = { xs: 4, md: 6 };
const CARD_SPACING = 3;
const CONTENT_PADDING = { xs: 2, sm: 3, md: 4 };
const ROTATION_INTERVAL = 8000;
const FADE_DURATION = 1000;

const cardShadow = '0 4px 20px rgba(0,0,0,0.12)';
const cardHoverShadow = '0 6px 25px rgba(0,0,0,0.2)';

const HeroImage: React.FC<HeroImageProps> = ({
    src,
    alt,
    className
}) => {
    const [imgSrc, setImgSrc] = useState<string | null>(() => {
        const url = src;
        if (!url) return null;

        if (url.startsWith('http')) return url;
        if (url.startsWith('data:')) return url;
        if (url.startsWith('/static/')) return url;

        let normalizedUrl = url;
        if (url.startsWith('/')) {
            normalizedUrl = url.substring(1);
        }

        if (!normalizedUrl.startsWith('media/')) {
            normalizedUrl = `media/${normalizedUrl}`;
        }

        return `${S3_BUCKET_URL}/${normalizedUrl}`;
    });

    useEffect(() => {
        const normalizeUrl = (rawUrl: string | null | undefined) => {
            if (!rawUrl) return null;

            if (rawUrl.startsWith('http')) return rawUrl;
            if (rawUrl.startsWith('data:')) return rawUrl;
            if (rawUrl.startsWith('/static/')) return rawUrl;

            let normalized = rawUrl;
            if (rawUrl.startsWith('/')) {
                normalized = rawUrl.substring(1);
            }

            if (!normalized.startsWith('media/')) {
                normalized = `media/${normalized}`;
            }

            return `${S3_BUCKET_URL}/${normalized}`;
        };

        setImgSrc(normalizeUrl(src));
    }, [src]);

    if (!imgSrc) return null;

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                backgroundImage: `url(${imgSrc})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                transition: 'opacity 0.5s ease-in-out'
            }}
            className={className}
        />
    );
};

const StaticImageWithFallback: React.FC<{
    src: string | undefined | null;
    alt: string;
    className?: string;
}> = ({ src, alt, className }) => {
    const [imgSrc, setImgSrc] = useState(() => {
        const url = src;
        if (!url) return FALLBACK_IMAGE;

        if (url.startsWith('http')) return url;
        if (url.startsWith('data:')) return url;
        if (url.startsWith('/static/')) return url;

        let normalizedUrl = url;
        if (url.startsWith('/')) {
            normalizedUrl = url.substring(1);
        }

        if (!normalizedUrl.startsWith('media/')) {
            normalizedUrl = `media/${normalizedUrl}`;
        }

        return `${S3_BUCKET_URL}/${normalizedUrl}`;
    });

    return (
        <Box
            component="img"
            src={imgSrc}
            alt={alt}
            className={className}
            onError={() => setImgSrc(FALLBACK_IMAGE)}
            sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                transition: 'opacity 0.3s ease',
            }}
        />
    );
};

const LandingPage: React.FC<LandingPageProps> = ({
    validImageStories,
    stories,
    popularClasses,
    currentImageIndex,
    loading,
    error,
}) => {
    const navigate = useNavigate();
    const [scrolled, setScrolled] = useState(false);
    const [imageState, setImageState] = useState<HeroImageState>({
        currentIndex: currentImageIndex,
        nextIndex: (currentImageIndex + 1) % validImageStories.length,
        isTransitioning: false
    });

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (!validImageStories.length) return;

        const rotateImage = () => {
            setImageState(prev => ({
                currentIndex: prev.nextIndex,
                nextIndex: (prev.nextIndex + 1) % validImageStories.length,
                isTransitioning: true
            }));

            setTimeout(() => {
                setImageState(prev => ({
                    ...prev,
                    isTransitioning: false
                }));
            }, FADE_DURATION);
        };

        const intervalId = setInterval(rotateImage, ROTATION_INTERVAL);
        return () => clearInterval(intervalId);
    }, [validImageStories.length]);

    const handleScrollDown = () => {
        const nextSection = document.getElementById('whyStorybyte');
        if (nextSection) {
            nextSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ mt: 4 }}>
                <Alert severity="error">{error}</Alert>
            </Box>
        );
    }

    return (
        <Box sx={{ width: '100%', position: 'relative', overflow: 'hidden', ...keyframes }}>
            {/* Hero Section */}
            <Box sx={{
                height: '100vh',
                width: '100%',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                {/* Background Images Container */}
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 0,
                    overflow: 'hidden',
                }}>
                    {/* Current Image */}
                    <Box sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        opacity: imageState.isTransitioning ? 0 : 1,
                        transition: `opacity ${FADE_DURATION}ms ease-in-out`,
                    }}>
                        <HeroImage
                            src={validImageStories[imageState.currentIndex]?.current_node?.image_url}
                            alt={validImageStories[imageState.currentIndex]?.title || "Story scene"}
                            className="story-cover"
                        />
                    </Box>

                    {/* Next Image */}
                    <Box sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        opacity: imageState.isTransitioning ? 1 : 0,
                        transition: `opacity ${FADE_DURATION}ms ease-in-out`,
                    }}>
                        <HeroImage
                            src={validImageStories[imageState.nextIndex]?.current_node?.image_url}
                            alt={validImageStories[imageState.nextIndex]?.title || "Story scene"}
                            className="story-cover"
                        />
                    </Box>
                </Box>

                {/* Gradient Overlay */}
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.3) 100%)',
                    zIndex: 1,
                }} />

                {/* Hero Content */}
                <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 2 }}>
                    <Box sx={{
                        textAlign: 'center',
                        color: 'white',
                        animation: 'fadeInUp 0.8s ease forwards',
                    }}>
                        <Typography
                            variant="h1"
                            sx={{
                                fontFamily: '"Cal Sans", "Inter", system-ui, -apple-system, sans-serif',
                                fontWeight: 800,
                                fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem' },
                                mb: 3,
                                letterSpacing: '-0.02em',
                                lineHeight: 1.2,
                                color: '#ffffff',
                                position: 'relative',
                                zIndex: 1,
                                textShadow: `
                                    0 4px 12px rgba(0,0,0,0.5),
                                    0 8px 24px rgba(0,0,0,0.3)
                                `,
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    background: 'radial-gradient(circle at center, rgba(255,255,255,0.1) 0%, transparent 70%)',
                                    filter: 'blur(8px)',
                                    zIndex: -1
                                },
                                animation: 'fadeInUp 0.8s ease forwards',
                                transform: 'perspective(1000px) translateZ(0)',
                            }}
                        >
                            Byte sized learning.
                        </Typography>

                        {/* Call to Action Buttons */}
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 2.5,
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: 'center',
                            mt: 6,
                            opacity: 0,
                            animation: 'fadeInUp 0.8s ease forwards 0.3s',
                        }}>
                            <Button
                                variant="contained"
                                size="large"
                                startIcon={<Create />}
                                onClick={() => navigate("/login")}
                                sx={{
                                    minWidth: { xs: '80%', sm: 200 },
                                    py: 2,
                                    px: 4,
                                    background: 'linear-gradient(45deg, #FF8A00, #FF4F00)',
                                    borderRadius: 2,
                                    fontSize: '1.1rem',
                                    textTransform: 'none',
                                    fontWeight: 600,
                                    boxShadow: '0 4px 15px rgba(0,0,0,0.3), 0 4px 15px rgba(255, 138, 0, 0.3)',
                                    transition: 'all 0.3s ease',
                                    textShadow: '0 2px 8px rgba(0,0,0,0.5)',
                                    '&:hover': {
                                        background: 'linear-gradient(45deg, #FF9500, #FF5F00)',
                                        boxShadow: '0 6px 20px rgba(0,0,0,0.4), 0 6px 20px rgba(255, 138, 0, 0.4)',
                                        transform: 'translateY(-2px)',
                                    },
                                }}
                            >
                                Create Story
                            </Button>
                            <Button
                                variant="text"
                                size="large"
                                startIcon={<Explore />}
                                onClick={() => navigate("/login")}
                                sx={{
                                    minWidth: { xs: '80%', sm: 200 },
                                    py: 2,
                                    px: 4,
                                    fontSize: '1.1rem',
                                    textTransform: 'none',
                                    fontWeight: 600,
                                    color: 'white',
                                    borderRadius: 2,
                                    background: 'rgba(255, 255, 255, 0.1)',
                                    backdropFilter: 'blur(10px)',
                                    WebkitBackdropFilter: 'blur(10px)',
                                    border: '1px solid rgba(255,255,255,0.2)',
                                    textShadow: '0 2px 8px rgba(0,0,0,0.5)',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        background: 'rgba(255, 255, 255, 0.15)',
                                        borderColor: 'rgba(255,255,255,0.3)',
                                        transform: 'translateY(-2px)',
                                    },
                                }}
                            >
                                Explore Stories
                            </Button>
                        </Box>
                    </Box>
                </Container>

                {/* Scroll Indicator */}
                <Box
                    onClick={handleScrollDown}
                    sx={{
                        position: 'absolute',
                        bottom: { xs: 24, md: 40 },
                        left: 0,
                        right: 0,
                        margin: '0 auto',
                        width: 'fit-content',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        color: 'white',
                        cursor: 'pointer',
                        opacity: 0,
                        animation: 'fadeInUp 0.8s ease forwards 0.6s',
                        zIndex: 2,
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            mb: 1,
                            textTransform: 'uppercase',
                            letterSpacing: '0.1em',
                            fontSize: '0.8rem',
                            fontWeight: 500,
                        }}
                    >
                        Scroll Down
                    </Typography>
                    <KeyboardArrowDown sx={{
                        fontSize: 32,
                        filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))',
                        animation: 'scrollDown 2s ease-in-out infinite',
                    }} />
                </Box>
            </Box>

            {/* Main Content */}
            <Container maxWidth={false} sx={{
                maxWidth: '2000px',
                px: CONTENT_PADDING,
                pb: SECTION_SPACING
            }}>
                {/* Why Storybyte Section */}
                <Box id="whyStorybyte" sx={{
                    pt: SECTION_SPACING,
                    scrollMarginTop: '80px'
                }}>
                    <WhyStorybyte />
                </Box>

                {/* Roadmap Section */}
                <Box sx={{ mt: SECTION_SPACING }}>
                    <RoadmapSection />
                </Box>

                {/* Stories Grid Section */}
                <Box sx={{ mt: SECTION_SPACING }}>
                    <Typography variant="h3" align="center" sx={{
                        mb: 6,
                        fontWeight: 600,
                        fontSize: { xs: '2rem', md: '2.5rem' },
                    }}>
                        Popular Stories
                    </Typography>
                    <Grid container spacing={CARD_SPACING}>
                        {stories.slice(0, 6).map((story) => (
                            <Grid item xs={12} sm={6} md={4} key={story.id}>
                                <Card sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: 2,
                                    boxShadow: cardShadow,
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        transform: 'translateY(-4px)',
                                        boxShadow: cardHoverShadow,
                                    },
                                }}>
                                    <CardActionArea
                                        onClick={() => navigate("/login")}
                                        sx={{ flexGrow: 1 }}
                                    >
                                        <Box sx={{ height: 200, overflow: 'hidden' }}>
                                            <StaticImageWithFallback
                                                src={story.current_node?.image_url}
                                                alt={story.title}
                                                className="story-cover"
                                            />
                                        </Box>
                                        <CardContent sx={{ p: 3 }}>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    fontSize: '1.25rem',
                                                    fontWeight: 600,
                                                    mb: 1,
                                                }}
                                            >
                                                {story.title}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                sx={{
                                                    mb: 2,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: 3,
                                                    WebkitBoxOrient: 'vertical',
                                                }}
                                            >
                                                {story.context}
                                            </Typography>
                                            <Box sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                mt: 2,
                                                pt: 2,
                                                borderTop: '1px solid rgba(0,0,0,0.08)',
                                            }}>
                                                <Chip
                                                    avatar={<Avatar>{story.author?.[0] || "A"}</Avatar>}
                                                    label={story.author || "Anonymous"}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                                <Box sx={{ display: 'flex', gap: 1 }}>
                                                    <Chip
                                                        icon={<TrendingUp sx={{ fontSize: 16 }} />}
                                                        label={story.plays}
                                                        size="small"
                                                    />
                                                    <Chip
                                                        icon={<School sx={{ fontSize: 16 }} />}
                                                        label={story.likes}
                                                        size="small"
                                                    />
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                {/* Popular Classes Section */}
                <Box sx={{ mt: SECTION_SPACING }}>
                    <Typography variant="h3" align="center" sx={{
                        mb: 6,
                        fontWeight: 600,
                        fontSize: { xs: '2rem', md: '2.5rem' },
                    }}>
                        Popular Classes
                    </Typography>
                    <Grid container spacing={CARD_SPACING}>
                        {popularClasses.map((classItem) => (
                            <Grid item xs={12} sm={6} md={4} key={classItem.id}>
                                <Card sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: 2,
                                    boxShadow: cardShadow,
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        transform: 'translateY(-4px)',
                                        boxShadow: cardHoverShadow,
                                    },
                                }}>
                                    <CardActionArea
                                        onClick={() => navigate("/login")}
                                        sx={{
                                            flexGrow: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '100%'
                                        }}
                                    >
                                        <CardContent sx={{
                                            p: 3,
                                            flexGrow: 1,
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    fontSize: '1.25rem',
                                                    fontWeight: 600,
                                                    mb: 2,
                                                }}
                                            >
                                                {classItem.name}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                sx={{
                                                    mb: 2,
                                                    flexGrow: 1,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: 3,
                                                    WebkitBoxOrient: 'vertical',
                                                }}
                                            >
                                                {classItem.description}
                                            </Typography>
                                            <Box sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                mt: 'auto',
                                                pt: 2,
                                                borderTop: '1px solid rgba(0,0,0,0.08)',
                                            }}>
                                                <Chip
                                                    avatar={<Avatar>{classItem.owner[0]}</Avatar>}
                                                    label={classItem.owner}
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{
                                                        borderColor: 'rgba(0,0,0,0.1)',
                                                        '.MuiChip-avatar': {
                                                            bgcolor: 'rgba(0,0,0,0.08)',
                                                            color: 'rgba(0,0,0,0.7)',
                                                        }
                                                    }}
                                                />
                                                <Box sx={{ display: 'flex', gap: 1 }}>
                                                    <Chip
                                                        icon={<People sx={{ fontSize: 16 }} />}
                                                        label={classItem.students_count}
                                                        size="small"
                                                        sx={{
                                                            bgcolor: 'rgba(0,0,0,0.04)',
                                                            '.MuiChip-icon': {
                                                                color: 'rgba(0,0,0,0.6)',
                                                            }
                                                        }}
                                                    />
                                                    <Chip
                                                        icon={<MenuBook sx={{ fontSize: 16 }} />}
                                                        label={classItem.stories_count}
                                                        size="small"
                                                        sx={{
                                                            bgcolor: 'rgba(0,0,0,0.04)',
                                                            '.MuiChip-icon': {
                                                                color: 'rgba(0,0,0,0.6)',
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                {/* Call to Action Section */}
                <Box sx={{
                    py: { xs: 8, md: 12 },
                    px: 3,
                    bgcolor: '#1a1a1a',
                    color: "white",
                    textAlign: "center",
                    mt: SECTION_SPACING,
                    borderRadius: 2,
                }}>
                    <Container maxWidth="md">
                        <Typography variant="h4" sx={{
                            mb: 3,
                            fontWeight: 600,
                            fontSize: { xs: '1.75rem', md: '2.25rem' },
                        }}>
                            Ready to start your learning journey?
                        </Typography>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={() => navigate("/signup")}
                            sx={{
                                py: 1.5,
                                px: 4,
                                fontSize: '1.1rem',
                                bgcolor: "white",
                                color: "#1a1a1a",
                                textTransform: 'none',
                                fontWeight: 600,
                                borderRadius: 2,
                                transition: 'all 0.2s ease',
                                '&:hover': {
                                    bgcolor: "rgba(255,255,255,0.9)",
                                    transform: 'translateY(-2px)',
                                    boxShadow: '0 6px 20px rgba(255,255,255,0.2)',
                                }
                            }}
                        >
                            Sign Up Now
                        </Button>
                    </Container>
                </Box>
            </Container>
        </Box>
    );
};

export default LandingPage;