import React from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import {
  AutoStories as BookIcon,
  Psychology as BrainIcon,
  Group as UsersIcon,
  Grade as StarIcon,
  EmojiEvents as TrophyIcon,
} from "@mui/icons-material";

const RoadmapSection = () => {
  const steps = [
    {
      icon: BookIcon,
      title: "Choose Your Story",
      description: "Start your journey by selecting education stories from our library or create a new personalized story",
      gradient: "linear-gradient(45deg, #FF8A00, #FF4F00)"
    },
    {
      icon: BrainIcon,
      title: "Learn Through Choices",
      description: "Make decisions that shape your story while learning key concepts",
      gradient: "linear-gradient(45deg, #FF4F00, #FF8A00)"
    },
    {
      icon: UsersIcon,
      title: "Join Classrooms",
      description: "Connect with teachers and classmates in private learning spaces",
      gradient: "linear-gradient(45deg, #FF8A00, #FF4F00)"
    },
    {
      icon: StarIcon,
      title: "Track Progress",
      description: "Watch your knowledge grow through interactive storytelling",
      gradient: "linear-gradient(45deg, #FF4F00, #FF8A00)"
    },
    {
      icon: TrophyIcon,
      title: "Master Concepts",
      description: "Complete stories to demonstrate your understanding",
      gradient: "linear-gradient(45deg, #FF8A00, #FF4F00)"
    }
  ];

  return (
    <Box sx={{
      maxWidth: '1400px',
      mx: 'auto',
      px: { xs: 2, sm: 4 },
      mt: 12,
      mb: 8
    }}>
      <Box sx={{
        py: 6,
        background: 'linear-gradient(135deg, rgba(255,255,255,0.97) 0%, rgba(252,253,254,0.97) 100%)',
        backdropFilter: 'blur(10px)',
        borderRadius: '20px',
        position: 'relative',
        overflow: 'hidden',
        boxShadow: '0 4px 20px rgba(0,0,0,0.06)',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'radial-gradient(circle at 30% -10%, #FF8A00 0%, transparent 35%), radial-gradient(circle at 70% 110%, #FF4F00 0%, transparent 35%)',
          opacity: 0.05,
          zIndex: 0
        }
      }}>
        <Box sx={{
          position: 'relative',
          zIndex: 1,
          px: { xs: 2, sm: 4 },
        }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: 4, sm: 3 },
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              left: { xs: '24px', md: '64px' },
              right: { xs: '24px', md: '64px' },
              top: { xs: '0', md: '44px' },
              height: '4px',
              background: 'linear-gradient(to right, #FF8A00, #FF4F00)',
              borderRadius: '2px',
              zIndex: 0,
              display: { xs: 'none', md: 'block' }
            }
          }}>
            {steps.map((step, index) => (
              <Box
                key={step.title}
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  position: 'relative',
                  animation: `popIn 0.5s ease-out ${index * 0.2}s both`,
                  '@keyframes popIn': {
                    '0%': {
                      opacity: 0,
                      transform: 'scale(0.5) translateY(20px)'
                    },
                    '100%': {
                      opacity: 1,
                      transform: 'scale(1) translateY(0)'
                    }
                  }
                }}
              >
                <Box sx={{
                  width: '2px',
                  height: '40px',
                  background: step.gradient,
                  mb: 2,
                  display: { xs: 'none', md: 'block' }
                }} />

                <Box sx={{
                  width: 48,
                  height: 48,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: step.gradient,
                  boxShadow: '0 4px 20px rgba(255, 138, 0, 0.3)',
                  position: 'relative',
                  zIndex: 1,
                  mb: 2,
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.1)',
                  }
                }}>
                  <step.icon sx={{ 
                    color: 'white', 
                    fontSize: 24,
                    filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))'
                  }} />
                </Box>

                <Card sx={{
                  width: '100%',
                  background: 'rgba(255,255,255,0.9)',
                  backdropFilter: 'blur(10px)',
                  borderRadius: 4,
                  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 6px 25px rgba(0,0,0,0.15)',
                  }
                }}>
                  <CardContent sx={{ 
                    p: 2.5,
                    minHeight: 100
                  }}>
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 1,
                        background: step.gradient,
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        fontWeight: 'bold',
                        fontSize: { xs: '1rem', md: '1.1rem' },
                        textAlign: 'center'
                      }}
                    >
                      {step.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: { xs: '0.825rem', md: '0.875rem' },
                        color: 'rgba(0,0,0,0.7)',
                        textAlign: 'center',
                        lineHeight: 1.5
                      }}
                    >
                      {step.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RoadmapSection;