import React, { useState } from 'react';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import { Typography, Button, Box, TextField, Divider, Link, Paper } from '@mui/material';
import axios from 'axios';
import { setupAxios } from '../utils/auth';
import { API_BASE_URL } from '../config';

interface LoginProps {
  onLogin: () => void;
}

interface LocationState {
  from: string;
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
      const response = await axios.post(`${API_BASE_URL}/accounts/login/`, {
        username: identifier, // Backend will handle both email and username
        password
      });
      
      localStorage.setItem('token', response.data.token);
      setupAxios();
      onLogin();
      const state = location.state as LocationState;
      navigate(state?.from || '/', { replace: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.error('Login error response:', error.response);
        setError(error.response.data.detail || 'Invalid credentials');
      } else {
        console.error('Unexpected error:', error);
        setError('An unexpected error occurred');
      }
    }
  };

  return (
    <Box 
      sx={{ 
        mt: { xs: 12, sm: 14 }, // Increased margin-top for navbar spacing
        mx: 'auto', // Center horizontally
        px: 2, // Horizontal padding for mobile
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Paper 
        elevation={3}
        sx={{
          p: 4,
          maxWidth: 400,
          width: '100%',
          borderRadius: 2,
          background: 'rgba(255, 255, 255, 0.98)',
          backdropFilter: 'blur(10px)'
        }}
      >
        <Typography 
          variant="h4" 
          component="h1" 
          gutterBottom
          sx={{
            fontWeight: 600,
            textAlign: 'center',
            mb: 3,
            color: '#1a1a1a'
          }}
        >
          Welcome Back
        </Typography>

        {error && (
          <Typography 
            color="error" 
            sx={{ 
              mb: 2,
              textAlign: 'center',
              backgroundColor: 'rgba(211, 47, 47, 0.1)',
              p: 1,
              borderRadius: 1
            }}
          >
            {error}
          </Typography>
        )}

        <Box 
          component="form" 
          onSubmit={handleSubmit}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <TextField
            fullWidth
            label="Email or Username"
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
            required
            autoComplete="username"
            placeholder="Enter your email or username"
            sx={{
              '& .MuiOutlinedInput-root': {
                bgcolor: 'white'
              }
            }}
          />

          <TextField
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            autoComplete="current-password"
            sx={{
              '& .MuiOutlinedInput-root': {
                bgcolor: 'white'
              }
            }}
          />

          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end'
          }}>
            <Link
              component={RouterLink}
              to="/forgot-password"
              sx={{
                fontSize: '0.875rem',
                color: 'primary.main',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              Forgot password?
            </Link>
          </Box>

          <Button 
            type="submit" 
            variant="contained" 
            fullWidth
            sx={{ 
              py: 1.5,
              fontSize: '1rem',
              fontWeight: 500,
              textTransform: 'none',
              borderRadius: 1.5,
              bgcolor: '#1a1a1a',
              '&:hover': {
                bgcolor: '#333'
              }
            }}
          >
            Login
          </Button>

          <Divider sx={{ my: 2 }}>
            <Typography variant="body2" color="text.secondary">
              OR
            </Typography>
          </Divider>

          <Button
            component={RouterLink}
            to="/signup"
            variant="outlined"
            fullWidth
            sx={{ 
              py: 1.5,
              fontSize: '1rem',
              fontWeight: 500,
              textTransform: 'none',
              borderRadius: 1.5,
              borderColor: '#1a1a1a',
              color: '#1a1a1a',
              '&:hover': {
                borderColor: '#333',
                bgcolor: 'rgba(26, 26, 26, 0.04)'
              }
            }}
          >
            New user? Sign up here
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default Login;