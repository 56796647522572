import React, { useState, useEffect } from 'react';
import { Box, Link, IconButton, Collapse, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChatIcon from '@mui/icons-material/Chat';

const DiscordBanner = () => {
  const [show, setShow] = useState<boolean | null>(null);

  useEffect(() => {
    const isClosed = localStorage.getItem('discordBannerClosed');
    const timer = setTimeout(() => {
      setShow(!isClosed);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setShow(false);
    localStorage.setItem('discordBannerClosed', 'true');
  };

  if (show === null) return null;

  return (
    <Collapse in={show} collapsedSize={0}>
      <Box sx={{
        width: '100%',
        background: `linear-gradient(-45deg, #FF8A00, #FF4F00, #FF8A00, #ffffff)`,
        backgroundSize: '400% 400%',
        animation: 'gradient 15s ease infinite',
        padding: '12px 24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        height: '48px',
        '@keyframes gradient': {
          '0%': {
            backgroundPosition: '0% 50%'
          },
          '50%': {
            backgroundPosition: '100% 50%'
          },
          '100%': {
            backgroundPosition: '0% 50%'
          }
        }
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          width: '100%',
          maxWidth: '1200px',
          color: 'white',
          flexWrap: { xs: 'wrap', sm: 'nowrap' }
        }}>
          <Typography
            variant="h6"
            component="span"
            sx={{
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              fontSize: { xs: '0.9rem', sm: '1.25rem' },
              color: 'white',
              textShadow: '1px 1px 2px rgba(0,0,0,0.1)'
            }}
          >
            🎓 UNLV Students! Help shape Storybyte
          </Typography>
          <Link
            href="https://discord.gg/vm6ttA5brC"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: '#FF4F00',
              backgroundColor: 'white',
              padding: '8px 20px',
              borderRadius: '20px',
              textDecoration: 'none',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              transition: 'all 0.2s ease-in-out',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              '&:hover': {
                backgroundColor: 'rgba(255,255,255,0.9)',
                transform: 'translateY(-1px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
              }
            }}
          >
            <ChatIcon sx={{ fontSize: 20 }} />
            Join Discord
          </Link>
        </Box>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: '50%',
            transform: 'translateY(-50%)',
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Collapse>
  );
};

export default DiscordBanner;