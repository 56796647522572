import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box, Typography, Button, Card, CardContent, CardMedia, Grid, Chip,
  IconButton, Alert, Snackbar, Paper
} from '@mui/material';
import {
  Favorite,
  FavoriteBorder,
  BookmarkAdd,
  BookmarkAdded,
  ArrowBack as ArrowBackIcon
} from '@mui/icons-material';
import axios from 'axios';
import StoryEnd from './StoryEnd';
import { treadmill } from 'ldrs'
treadmill.register()

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';
const FALLBACK_IMAGE = '/static/images/fallback_cover.png';

interface StoryData {
  id: number;
  title: string;
  current_node: StoryNode;
  current_scene: number;
  plays: number;
  likes: number;
  shares: number;
  cache_percentage: number;
  cover_image?: string;
  is_liked: boolean;
  is_saved: boolean;
  like_count: number;
}

interface StoryNode {
  content: string;
  choices: string[];
  scene_number: number;
  image_prompt: string;
  image_url: string;
  summary: string;
  characters: string[];
  parent_choice?: string;
}

interface FormattedTextProps {
  text: string;
}

const FormattedText: React.FC<FormattedTextProps> = ({ text }) => {
  const formatText = (content: string) => {
    const sentences = content.split(/(\n+|"[^"]+?")/g);

    return sentences.map((part: string, index: number) => {
      if (!part.trim()) {
        return null;
      }

      if (part.trim().startsWith('"') && part.trim().endsWith('"')) {
        const nextPart = sentences[index + 1] || '';
        const speakerMatch = nextPart.match(/,?\s*([\w\s]+)\s*(proclaimed|said|murmured|mused|chimed in|suggested|observed|whispered|asked|replied)/i);
        const speaker = speakerMatch ? speakerMatch[1].trim() : '';

        return (
          <div key={index} className="flex flex-col mb-4">
            <div className="flex items-start gap-3">
              {speaker && (
                <span className="text-sm font-semibold text-gray-800 mt-1 min-w-[100px] border-r pr-3">
                  {speaker}
                </span>
              )}
              <p className="text-lg flex-1 font-light italic">
                {part.trim()}
              </p>
            </div>
          </div>
        );
      } else if (part.trim()) {
        return (
          <p key={index} className="text-gray-700 mb-4 leading-relaxed text-lg">
            {part.trim()}
          </p>
        );
      }
      return null;
    }).filter(Boolean);
  };

  return (
    <div className="prose max-w-none">
      {formatText(text)}
    </div>
  );
};

const Story: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [story, setStory] = useState<StoryData | null>(null);
  const [currentNode, setCurrentNode] = useState<StoryNode | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingStep, setLoadingStep] = useState('Loading story...');
  const [error, setError] = useState<string | null>(null);
  const [showEnding, setShowEnding] = useState(false);
  const [failedImages, setFailedImages] = useState<Set<string>>(new Set());
  const [playRecorded, setPlayRecorded] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [resetCounter, setResetCounter] = useState(0);

  const getImageUrl = useCallback((url: string | undefined): string => {
    if (!url) return FALLBACK_IMAGE;
    if (url.startsWith('http') || url.startsWith('data:')) return url;
    if (process.env.NODE_ENV === 'production') {
      return `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${url}`;
    } else {
      return `/media/${url}`;
    }
  }, []);

  const fetchStory = useCallback(async () => {
    if (!id) return;
    setLoading(true);
    setLoadingStep('Connecting to server...');
    setError(null);
    try {
      setLoadingStep('Fetching story data...');
      const response = await axios.get<StoryData>(`${API_BASE_URL}/stories/${id}/`);
      setLoadingStep('Processing story content...');
      setStory(response.data);
      setCurrentNode(response.data.current_node);
      console.log('Fetched story data:', response.data);
    } catch (error) {
      console.error('Error fetching story:', error);
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 404) {
          setError('Story not found or has been removed due to incompleteness.');
        } else if (error.response.status === 401) {
          navigate('/login', { state: { from: `/story/${id}` } });
        } else {
          setError(`Failed to fetch story: ${error.response.data.detail || error.response.statusText}`);
        }
      } else {
        setError('Failed to fetch story. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  }, [id, navigate]);

  useEffect(() => {
    fetchStory();
  }, [fetchStory, resetCounter]);

  useEffect(() => {
    if (currentNode && currentNode.image_url && !failedImages.has(currentNode.image_url)) {
      console.log('Current image URL:', getImageUrl(currentNode.image_url));
    }
  }, [currentNode, failedImages, getImageUrl]);

  const handlePlay = useCallback(async () => {
    if (!story || !id) return;
    try {
      const response = await axios.post<StoryData>(`${API_BASE_URL}/stories/${id}/play/`);
      setStory(prevStory => prevStory ? ({
        ...prevStory,
        plays: response.data.plays
      }) : null);
    } catch (error) {
      console.error('Error incrementing play count:', error);
      setSnackbar({ open: true, message: 'Failed to record play. Please try again.' });
    }
  }, [id, story]);

  useEffect(() => {
    if (story && !playRecorded) {
      handlePlay();
      setPlayRecorded(true);
    }
  }, [story, playRecorded, handlePlay]);

  useEffect(() => {
    async function getLoader() {
      const { treadmill } = await import('ldrs')
      treadmill.register()
    }
    getLoader()
  }, [])

  const handleChoice = async (choiceIndex: number) => {
    if (!id) return;
    setLoading(true);
    setLoadingStep('Processing your choice...');
    setError(null);
    try {
      console.log(`Making choice: index ${choiceIndex} for story ${id}`);
      const response = await axios.post<{ story: StoryData; is_conclusion: boolean; next_node: StoryNode }>(
        `${API_BASE_URL}/stories/${id}/make_choice/`,
        { choice_index: choiceIndex }
      );
      console.log('Server response:', response.data);

      setLoadingStep('Updating story state...');
      if (response.data.story && response.data.next_node) {
        setStory(response.data.story);
        setCurrentNode(response.data.next_node);
        console.log('Updated story state:', {
          currentScene: response.data.story.current_scene,
          hasChoices: response.data.next_node.choices?.length > 0
        });
        setResetCounter(prev => prev + 1);
      } else {
        console.error('Unexpected response structure:', response.data);
        setError('Received unexpected data format from server');
      }
    } catch (error) {
      console.error('Error making choice:', error);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error('Error response:', error.response.data);
          setError(`Failed to process choice: ${error.response.data.error || error.response.statusText}`);
        } else if (error.request) {
          console.error('No response received:', error.request);
          setError('No response received from server. Please check your connection and try again.');
        } else {
          console.error('Error setting up request:', error.message);
          setError('An error occurred while setting up the request. Please try again.');
        }
      } else {
        console.error('Non-Axios error:', error);
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRestart = async () => {
    if (!id) return;
    setLoading(true);
    setLoadingStep('Resetting story...');
    setError(null);
    try {
      const response = await axios.post<StoryData>(`${API_BASE_URL}/stories/${id}/reset_story/`);
      console.log('Reset response:', response.data);
      setStory(response.data);
      setCurrentNode(response.data.current_node);
      setShowEnding(false);
      setPlayRecorded(false);
      setResetCounter(prev => prev + 1);
      console.log('Story reset, new image URL:', getImageUrl(response.data.current_node.image_url));
    } catch (error) {
      console.error('Error resetting story:', error);
      setError('Failed to reset story. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = async () => {
    if (!id || !story || story.current_scene <= 1) {
      setSnackbar({ open: true, message: "You are already at the beginning of the story." });
      return;
    }

    setLoading(true);
    setLoadingStep('Going to previous scene...');
    setError(null);

    try {
      // Log the API URL being called
      console.log('Calling API:', `${API_BASE_URL}/stories/${id}/go_back/`);

      const response = await axios.post<StoryData>(
        `${API_BASE_URL}/stories/${id}/go_back/`,
        {}, // Empty body
        {
          headers: {
            'Content-Type': 'application/json',
            // Include any authentication headers if needed
            ...(localStorage.getItem('token') && {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            })
          },
          // Add error handling options
          validateStatus: (status) => {
            return status >= 200 && status < 500; // Handle all responses
          }
        }
      );

      // Log the response for debugging
      console.log('Go back response:', response);

      if (response.status === 200 && response.data) {
        // Update story state
        setStory(response.data);

        // Get the previous node from the response
        const previousNode = response.data.current_node;
        setCurrentNode(
          typeof previousNode === 'string' ? JSON.parse(previousNode) : previousNode
        );

        // Reset counter to force image refresh
        setResetCounter(prev => prev + 1);
      } else {
        throw new Error(response.data?.error || 'Failed to go back');
      }
    } catch (error) {
      console.error('Error going back:', error);

      // Log detailed error information
      if (axios.isAxiosError(error)) {
        console.error('Axios error details:', {
          status: error.response?.status,
          data: error.response?.data,
          headers: error.response?.headers,
          config: error.config
        });

        if (error.response?.status === 401) {
          // Handle unauthorized access
          navigate('/login', { state: { from: `/story/${id}` } });
          return;
        }

        if (error.response?.status === 400) {
          setSnackbar({
            open: true,
            message: error.response.data.error || 'You are already at the beginning of the story.'
          });
        } else if (error.response?.status === 404) {
          setSnackbar({
            open: true,
            message: 'Previous scene not found.'
          });
        } else {
          setError('Unable to go back. Please try again.');
        }
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLike = async () => {
    if (!story || !id) return;
    try {
      const response = await axios.post<StoryData>(`${API_BASE_URL}/stories/${id}/like/`);
      setStory(prevStory => prevStory ? ({
        ...prevStory,
        is_liked: response.data.is_liked,
        like_count: response.data.like_count
      }) : null);
      setSnackbar({ open: true, message: response.data.is_liked ? 'Story liked!' : 'Story unliked.' });
    } catch (error) {
      console.error('Error liking story:', error);
      if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
        navigate('/login', { state: { from: `/story/${id}` } });
      } else {
        setSnackbar({ open: true, message: 'Failed to like story. Please try again.' });
      }
    }
  };

  const handleSave = async () => {
    if (!story || !id) return;
    try {
      const response = await axios.post<StoryData>(`${API_BASE_URL}/stories/${id}/save/`);
      setStory(prevStory => prevStory ? ({
        ...prevStory,
        is_saved: response.data.is_saved
      }) : null);
      setSnackbar({ open: true, message: response.data.is_saved ? 'Story saved!' : 'Story unsaved.' });
    } catch (error) {
      console.error('Error saving story:', error);
      if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
        navigate('/login', { state: { from: `/story/${id}` } });
      } else {
        setSnackbar({ open: true, message: 'Failed to save story. Please try again.' });
      }
    }
  };

  if (loading) {
    console.log("Loading state triggered");
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        gap: 2
      }}>
        <l-treadmill
          size="70"
          speed="1.25"
          color="#1a1a1a"
        ></l-treadmill>
        <Typography variant="body1" color="text.secondary">
          {loadingStep}
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ mt: 4, maxWidth: 600, mx: 'auto', p: 3 }}>
        <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
        <Button
          variant="outlined"
          onClick={() => navigate('/')}
          startIcon={<ArrowBackIcon />}
          sx={{ mt: 2 }}
        >
          Back to Home
        </Button>
      </Box>
    );
  }

  if (!story || !currentNode) {
    return (
      <Box sx={{ mt: 4, maxWidth: 600, mx: 'auto', p: 3 }}>
        <Alert severity="warning" sx={{ mb: 2 }}>Story not found or has no content.</Alert>
        <Button
          variant="outlined"
          onClick={() => navigate('/')}
          startIcon={<ArrowBackIcon />}
          sx={{ mt: 2 }}
        >
          Back to Home
        </Button>
      </Box>
    );
  }

  if (showEnding) {
    return (
      <StoryEnd
        storyId={id || ''}
        title={story.title}
        coverImage={getImageUrl(story.cover_image) || getImageUrl(currentNode.image_url)}
        onRestart={handleRestart}
      />
    );
  }

  const isConclusion = story.current_scene === 8;

  return (
    <Box sx={{
      mt: 2,
      maxWidth: 800,
      mx: 'auto',
      px: { xs: 2, sm: 2, md: 3 },
      mb: 4
    }}>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          background: 'linear-gradient(to bottom, #ffffff, #f8f9fa)'
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          align="center"
          sx={{
            fontWeight: 600,
            mb: 3,
            color: '#1a1a1a',
            fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' }
          }}
        >
          {story.title}
        </Typography>

        <Card
          sx={{
            mb: 3,
            boxShadow: '0 4px 12px rgba(0,0,0,0.12)',
            borderRadius: 2,
            overflow: 'hidden'
          }}
        >
          <CardMedia
            key={`${resetCounter}-${currentNode.image_url}`}
            component="img"
            height="400"
            image={getImageUrl(currentNode.image_url) || getImageUrl(story.cover_image)}
            alt="Story scene"
            sx={{
              objectFit: 'cover',
              objectPosition: 'center'
            }}
            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
              const imageUrl = currentNode.image_url || story.cover_image;
              if (imageUrl) {
                console.error(`Failed to load image: ${getImageUrl(imageUrl)}`);
                setFailedImages(prev => new Set(prev).add(imageUrl));
              }
              const target = e.target as HTMLImageElement;
              target.onerror = null;
              target.src = FALLBACK_IMAGE;
            }}
          />
          <CardContent sx={{ p: 3 }}>
            <Typography variant="body1" sx={{
              fontSize: '1rem',
              lineHeight: 1.6,
              mb: 3
            }}>
              <FormattedText text={currentNode.content} />
            </Typography>

            {isConclusion ? (
              <Button
                fullWidth
                variant="contained"
                size="large"
                onClick={() => setShowEnding(true)}
                sx={{
                  mt: 2,
                  py: 1.5,
                  bgcolor: '#1a1a1a',
                  '&:hover': {
                    bgcolor: '#333333'
                  }
                }}
              >
                Finish Story
              </Button>
            ) : currentNode.choices && currentNode.choices.length > 0 ? (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {currentNode.choices.map((choice, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="inherit"
                      onClick={() => handleChoice(index)}
                      sx={{
                        height: 'auto',
                        minHeight: '50px',
                        whiteSpace: 'normal',
                        textAlign: 'left',
                        padding: '12px 16px',
                        lineHeight: 1.3,
                        fontSize: '0.9rem',
                        bgcolor: '#1a1a1a !important',
                        color: 'white',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          bgcolor: '#333333 !important',
                          transform: 'translateY(-2px)',
                          boxShadow: '0 4px 12px rgba(0,0,0,0.2)'
                        }
                      }}
                    >
                      <FormattedText text={choice} />
                    </Button>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography>This story has no choices available.</Typography>
            )}
          </CardContent>
        </Card>

        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
          gap: 1
        }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}>
            <IconButton
              onClick={handleLike}
              size="small"
              sx={{
                '&:hover': { transform: 'scale(1.1)' },
                transition: 'transform 0.2s'
              }}
            >
              {story.is_liked ? <Favorite color="error" /> : <FavoriteBorder />}
            </IconButton>
            <Typography variant="body2" sx={{ mr: 2 }}>
              {story.like_count || 0} likes
            </Typography>
            <IconButton
              onClick={handleSave}
              size="small"
              sx={{
                '&:hover': { transform: 'scale(1.1)' },
                transition: 'transform 0.2s'
              }}
            >
              {story.is_saved ? <BookmarkAdded color="primary" /> : <BookmarkAdd />}
            </IconButton>
            <Typography variant="body2">Save</Typography>
          </Box>
          <Typography variant="body1" sx={{ color: '#666' }}>
            Plays: {story.plays || 0}
          </Typography>
        </Box>

        {/* Story Progress and Navigation */}
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2
        }}>
          <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: 1
          }}>
            <Chip
              size="small"
              label={`Scene ${story.current_scene || 1}`}
              sx={{
                bgcolor: '#1a1a1a',
                color: 'white',
                fontWeight: 500
              }}
            />
            <Chip size="small" label={`${story.plays || 0} Plays`} />
            <Chip size="small" label={`${story.like_count || 0} Likes`} />
            <Chip size="small" label={`${story.shares || 0} Shares`} />
          </Box>

          <Box sx={{
            display: 'flex',
            gap: 1,
            flexWrap: 'wrap',
            justifyContent: 'center'
          }}>
            <Button
              variant="outlined"
              size="small"
              onClick={handleGoBack}
              startIcon={<ArrowBackIcon />}
              sx={{
                borderColor: '#1a1a1a',
                color: '#1a1a1a',
                '&:hover': {
                  borderColor: '#333333',
                  bgcolor: 'rgba(26,26,26,0.05)'
                }
              }}
            >
              Previous Scene
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleRestart}
              sx={{
                borderColor: '#1a1a1a',
                color: '#1a1a1a',
                '&:hover': {
                  borderColor: '#333333',
                  bgcolor: 'rgba(26,26,26,0.05)'
                }
              }}
            >
              Reset Story
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => navigate('/')}
              sx={{
                borderColor: '#1a1a1a',
                color: '#1a1a1a',
                '&:hover': {
                  borderColor: '#333333',
                  bgcolor: 'rgba(26,26,26,0.05)'
                }
              }}
            >
              Home
            </Button>
          </Box>
        </Box>
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Box>
  );
};

export default Story;